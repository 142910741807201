<template>
  <div class="details-container">
    <header>
      <div class="header-container">
        <h2 class="text-med new-serif bold">Examples</h2>
        <onboarding-button
          @click.native="showOnboardingModal = true"
          ref="onboardingButton"
        ></onboarding-button>
      </div>
      <button class="button" :disabled="!canComposeExample" @click="composeExample">
        Compose New Example
      </button>
      <div class="snackbar disabled-compose-button">
        This template will become available once tags are added!
      </div>
    </header>
    <div class="examples-list card-container" v-if="examples.length > 0">
      <div class="table-header">
        <div class="row-item smaller">Subject</div>
        <div class="row-item">Content</div>
        <div class="row-item smaller">Tags</div>
        <div class="row-item smaller">Actions</div>
      </div>
      <draggable
        handle=".draggable-icon"
        v-if="examples.length > 0"
        class="examples"
        v-model="examples"
        :disabled="!$can('change', template)"
        item-key="id"
      >
        <template #item="{ element: example, index: i }">
          <example-admin-row
            :key="example.id"
            :example="example"
            :can-move-up="i !== 0"
            :can-move-down="i < examples.length - 1"
            @move-up="moveItemUp"
            @move-down="moveItemDown"
            @approve="approve(example)"
            @unapprove="unapprove(example)"
            @update="editExample(example)"
            @delete="setExampleToDelete(example)"
            @view-full-example="openSubmissionModal($event)"
          />
        </template>
      </draggable>
    </div>
    <div class="empty-state" v-else>
      <img alt="" class="empty-image" src="/static/images/empty-states/empty-examples.png" />
      <p class="text-med serif">You haven't added any examples!</p>
      <p>Add examples to help inspire people as they write.</p>
    </div>

    <confirmation-dialog
      v-if="exampleToDelete"
      :danger-mode="true"
      accept-label="Delete"
      @cancel="exampleToDelete = null"
      @accept="onAcceptDelete"
    >
      <template #message>
        <h2 class="text-med">
          Are you sure you want to delete the example with the subject “{{
            exampleToDelete.subject
          }}”?
        </h2>
      </template>
    </confirmation-dialog>

    <submission-detail-modal
      v-if="viewFullExample && currentExample"
      :label-for-title="labelForTitle"
      :label-for-content="labelForContent"
      @close="closeSubmissionModal()"
    >
      <template #message>
        <h2 class="text-med">Example Details</h2>
      </template>
      <template #date>
        {{ currentExample.created_at }}
      </template>
      <template #username>
        {{ currentExample.author }}
      </template>
      <template #subject>
        <div class="input">
          {{ currentExample.subject }}
        </div>
      </template>
      <template #submission>
        <div class="input submission-full-content" v-html="currentExample.content"></div>
      </template>
    </submission-detail-modal>
    <template-examples-admin-modal
      v-if="showOnboardingModal"
      :template="template"
      @close="closeOnboarding"
    ></template-examples-admin-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { clone } from "lodash";
import Draggable from "vuedraggable";
import dayjs from "dayjs";

import ConfirmationDialog from "shared/components/dashboard/ConfirmationDialog.vue";
import SubmissionDetailModal from "shared/components/submission/SubmissionDetailModal.vue";
import ExampleAdminRow from "./ExampleAdminRow.vue";
import OnboardingButton from "../components/OnboardingButton.vue";
import TemplateExamplesAdminModal from "shared/components/template/TemplateExamplesAdminModal.vue";

export default {
  components: {
    ExampleAdminRow,
    ConfirmationDialog,
    SubmissionDetailModal,
    Draggable,
    OnboardingButton,
    TemplateExamplesAdminModal,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      exampleToDelete: null,
      currentExample: null,
      viewFullExample: false,
      showOnboardingModal: false,
    };
  },
  computed: {
    ...mapState("template", {
      allChecklistItems: "allChecklistItems",
    }),

    examples: {
      get() {
        return this.$store.state.template.examples;
      },
      async set(value) {
        const originalExamples = clone(this.$store.state.template.examples);
        try {
          await this.reorderExamples(value);
        } catch (error) {
          this.showSnackbar(error.message);
          this.setExamples(originalExamples);
        }
      },
    },

    checklistItems() {
      const template = this.template;
      return this.allChecklistItems.filter((cli) => cli.template_id == template.id);
    },

    canComposeExample() {
      return this.checklistItems.length !== 0;
    },

    labelForTitle() {
      const docType = this.template.document_type;
      if (docType === "email") {
        return "Subject";
      }
      return "Title";
    },

    labelForContent() {
      const docType = this.template.document_type;
      if (docType === "email") {
        return "Body";
      }
      return "Content";
    },
  },
  async mounted() {
    this.loading = true;
    await this.getExamples(this.template.id);
    this.loading = false;
  },

  methods: {
    ...mapActions(["createNewDraft", "showSnackbar"]),
    ...mapActions("template", {
      setExamples: "setExamples",
      getExamples: "getExamples",
      updateExample: "updateExample",
      reorderExamples: "reorderExamples",
      deleteExample: "deleteExample",
    }),

    approve(example) {
      const updatedExample = { ...example, is_approved: true };
      this.updateExample(updatedExample);
    },

    unapprove(example) {
      const updatedExample = { ...example, is_approved: false };
      this.updateExample(updatedExample);
    },

    setExampleToDelete(example) {
      this.exampleToDelete = example;
    },

    async onAcceptDelete() {
      const example = this.exampleToDelete;
      await this.deleteExample(example);
      this.getExamples(this.template.id);
      this.exampleToDelete = null;
    },

    composeExample() {
      this.createNewDraft({ template: this.template }).then((draft) => {
        this.$router.push({
          name: "CreateExample",
          params: {
            draftId: draft.id,
          },
        });
      });
    },

    async editExample(example) {
      const draft = await this.createNewDraft({ template: this.template });
      await this.$store.dispatch('drafting/updateOrSaveEmail', {
        subject: example.subject,
        content: example.content,
      });
      const email_id = this.$store.state.drafting.currentEmail.id;
      const newTags = example.tags.map(tag => ({ ...tag, email_id }));
      await Promise.all(
        newTags.map(tag => this.$store.dispatch("drafting/saveTag", tag))
      );

      this.$router.push({
        name: "EditExample",
        params: {
          templateId: this.template.id,
          exampleSlug: example.slug,
          draftId: draft.id,
        },
      });
    },

    moveItemUp(item) {
      this.moveItemDir({ item, up: true });
    },

    moveItemDown(item) {
      this.moveItemDir({ item, down: true });
    },

    moveItemDir({ item, up = false, down = false }) {
      const examples = clone(this.examples);
      const itemIndex = examples.indexOf(item);
      let otherIndex = itemIndex + 1;
      if (up || !down) {
        otherIndex = itemIndex - 1;
      }
      examples[itemIndex] = examples[otherIndex];
      examples[otherIndex] = item;

      this.reorderExamples(examples);
    },

    openSubmissionModal(example) {
      this.currentExample = {
        ...example,
        created_at: dayjs(example.created_at).format("MMM, D YYYY [at] h:mma"),
      };
      this.viewFullExample = true;
    },

    closeSubmissionModal() {
      this.currentExample = null;
      this.viewFullExample = false;
    },

    closeOnboarding() {
      this.showOnboardingModal = false;
      this.$refs.onboardingButton.$el.focus();
    },
  },
};
</script>
