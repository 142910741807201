<template>
  <div class="details-container">
      <header>
        <div class="header-container">
          <h2 class="text-med new-serif bold">Settings</h2>
          <onboarding-button
            @click.native="showOnboardingModal = true"
            ref="onboardingButton"
          ></onboarding-button>
        </div>
        <div class="button-container">
            <button class="button" :disabled="!isDirty" @click="saveSettings">Save</button>
            <button class="button-secondary" :disabled="!isDirty" @click="cancelSettings">Cancel</button>
        </div>
      </header>
      <div class="card-container">
          <div class="setting-container">
              <p class="setting-description description-text">Customize your template with the settings below.</p>
              <div class="setting-content-container">
                <div class="setting-content settings">
                  <h2>Template Settings</h2>
                  <label class="setting-item"><input type="checkbox" name="can_email" v-model="features.can_email"> Allow users to send draft as email directly from Lettersmith</label>
                  <label class="setting-item"><input type="checkbox" name="can_email" v-model="features.can_submit_example"> Allow users to submit drafts as examples</label>
                  <label class="setting-item"><input type="checkbox" name="can_email" v-model="features.can_submit_submission"> Allow users to submit drafts as submissions</label>
                  <label class="setting-item"><input type="checkbox" name="can_email" v-model="features.can_export"> Allow users to export their draft as Microsoft Word (.docx)</label>
                </div>
                <div class="setting-content settings">
                  <h2>Change 'Checklist' Label in Drafting Interface. (E.g Reminder, Considerations, etc):</h2>
                  <p class="description-text">The “Checklist” is the list of items provided by you to guide your participants through their writing. Participants will tag their draft with these items by associating each with a piece of text. If an alternative label might be more suitable for how you are using this feature, please change it below.</p>
                  <label class="setting-item text-input"><input class="input" type="text" placeholder="Checklist title to display in drafting interface" v-model="features.checklist_name" /></label>
                </div>
                <div class="setting-content">
                  <h2>Template Description</h2>
                  <p class="description-text">Provide your participants with a description of this template. This description will show in your participant's template overview modal and in a panel alongside their draft as they write.</p>
                  <rich-text-editor
                    ref="descriptionEditor"
                    :html="description"
                    @input="description = $event"
                  />
                </div>
                <div v-if="showCanvasTools" class="setting-content">
                  <h2>Canvas Course Association</h2>
                  <div v-if="!showCanvasAssociator">
                    Connect your account to Canvas in your <router-link :to="{ name: 'Account' }">Account settings</router-link> to associate this template with a course.
                  </div>
                  <div v-else-if="association === null">
                    No Canvas course associated:
                    <button class="button-secondary" @click="showSelectCourseModal = true">Add Canvas Association</button>
                  </div>
                  <div v-else-if="association">
                    Currently associated with: {{ association.name }}.
                    <button class="button-secondary" @click="onRemoveAssociation">Remove Canvas Association</button>
                  </div>
                  <select-course-modal
                    v-if="showSelectCourseModal"
                    :all-courses="courses"
                    :association-service="TemplateAPI"
                    :asset-id="template.id"
                    @close="showSelectCourseModal = false"
                    @new-association="onNewAssociation"
                  />
                </div>
              </div>
          </div>
      </div>
      <template-settings-modal
        v-if="showOnboardingModal"
        :template="template"
        @close="closeOnboarding"
      ></template-settings-modal>
  </div>
</template>

<script>
import { computed, defineComponent, ref, toRefs } from "vue";
import RichTextEditor from "shared/components/RichTextEditor.vue";
import SelectCourseModal from "shared/components/canvas/SelectCourseModal.vue";
import CanvasAPI from "~/services/canvas";
import TemplateAPI from "~/services/template";
import { removeCanvasAssociation } from "shared/composables/canvasAssociation";
import { useStore } from "vuex";
import { isEqual } from 'lodash';
import { onBeforeRouteLeave } from 'vue-router';

import OnboardingButton from "../components/OnboardingButton.vue";
import TemplateSettingsModal from "shared/components/template/TemplateSettingsModal.vue";

export default defineComponent({
  components: {
    RichTextEditor,
    SelectCourseModal,
    OnboardingButton,
    TemplateSettingsModal,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showOnboardingModal: false,
    };
  },
  methods: {
    closeOnboarding() {
      this.showOnboardingModal = false;
      this.$refs.onboardingButton.$el.focus();
    },
  },
  setup(props) {
    const { template } = toRefs(props);
    const store = useStore();

    const services = ref([]);
    const tokens = ref([]);
    const courses = ref([]);
    const showSelectCourseModal = ref(false);
    const association = ref(null);
    const showCanvasTools = computed(() => services.value.length > 0);
    const showCanvasAssociator = computed(() => tokens.value.length > 0);

    CanvasAPI.listServices().then((response) => {
      if (response.status === 200) {
        services.value = response.data;
      }
    });

    CanvasAPI.listTokens().then((response) => {
      if (response.status === 200) {
        tokens.value = response.data;
      }
    });

    CanvasAPI.courses().then((response) => {
      courses.value = response.data;
    });

    TemplateAPI.listCanvasAssociations(template.value.id).then((response) => {
      if (response.data) {
        association.value = response.data[0] || null;
      }
    });

    const onNewAssociation = (newAssociation) => {
      association.value = newAssociation;
      showSelectCourseModal.value = false;
    };
    const onRemoveAssociation = async () => {
      const removed = await removeCanvasAssociation(
        template.value.id,
        association.value.id,
        TemplateAPI
      );
      if (removed) {
        association.value = null;
      }
    };

    const initialDescription = ref(template.value.description);
    const description = ref(template.value.description);
    const initialFeatures = ref(template.value.features ?? {});
    const descriptionEditor = ref(null);
    const features = ref({
      ...initialFeatures.value
    });
    const isDirty = computed(() => {
      const differentFeatures = !isEqual(features.value, initialFeatures.value);
      const differentDescription = !isEqual(initialDescription.value, description.value);
      return differentFeatures || differentDescription;
    });
    const saveSettings = () => {
      store.dispatch("template/updateTemplateFeatures", {
        template: { ...template.value, description: description.value },
        features: features.value
      });
      initialFeatures.value = { ...features.value };
      initialDescription.value = description.value;
    };
    const cancelSettings = () => {
      features.value = { ...initialFeatures.value };
      description.value = initialDescription.value;
      descriptionEditor.value.editor.setHTML(description.value);
    };
    onBeforeRouteLeave((to, from, next) => {
      if (isDirty.value) {
        next(window.confirm("There are unsaved settings. Are you sure you want to continue without saving?"));
      } else {
        next();
      }
    });

    return {
      association,
      courses,
      onNewAssociation,
      onRemoveAssociation,
      showSelectCourseModal,
      showCanvasAssociator,
      showCanvasTools,
      TemplateAPI,

      cancelSettings,
      description,
      descriptionEditor,
      features,
      isDirty,
      saveSettings,
    };
  },
});
</script>
