<template>
  <ls-modal @close="$emit('close')" :large="true" :show-close="readyToClose" :visible="visible">
    <template #content>
      <h2 class="text-med">Add Members</h2>
      <div class="modal-body">
        <template v-if="!saving">
          <div class="modal-form-field">
            <label for="add-a-member-modal-emails" class="form-label bold">Email Addresses</label>
            <p class="description-text">
              You can add multiple members by entering their email addresses separated by placing each
              on a separate line, or separated by a space, comma or semi-colon.
            </p>
            <textarea class="input" id="add-a-member-modal-emails" v-model="email"></textarea>
          </div>
          <div class="modal-form-field">
            <div class="form-label bold">What role would you like these users to have?</div>
            <label class="member-role label-with-decription" v-for="roleName in roles" :key="roleName">
              <div class="role-name input-with-decription">
                <input type="radio" :value="roleName" v-model="role" />
                {{ roleName }}
              </div>
              <div class="description-text" v-if="roleName === 'owner'">Owners have all editing and deleting capabilities. You can have more than one owner on a template. Add owners if you have someone you would like to collaborate on this template with or transfer ownership to.</div>
              <div class="description-text" v-else-if="roleName === 'editor'">Editors are similar to owners, except they can't delete the template or the template owners. Add editors if you have someone you would like to collaborate on this template with.</div>
              <div class="description-text" v-else-if="roleName === 'participant'">Participants have student level permissions. They can create drafts and view examples.</div>
            </label>
          </div>
        </template>
        <template v-else>
          <ul class="saving-status">
            <add-member-status-item
              v-for="emailAddress in emailAddresses"
              :key="emailAddress"
              :email="emailAddress"
              :status="savingStatus[emailAddress] || 'pending'"
            />
          </ul>
        </template>
      </div>
    </template>
    <template #footer>
      <button v-if="!saving" class="button" @click="handleAddMembers" :disabled="!allowedToAdd">
        Add Members
      </button>
      <button
        v-else
        class="button"
        @click="
          saving = false;
          $emit('close');
        "
        :disabled="!readyToClose"
      >
        Close
      </button>
    </template>
  </ls-modal>
</template>

<script>
import { flattenDeep } from "lodash";

import Modal from "shared/components/Modal.vue";
import AddMemberStatusItem from "./AddMemberStatusItem.vue";

function findAllEmailAddresses(emailsString) {
  return flattenDeep(
    emailsString.split(/\s/).map((emails) => emails.split(",").map((emails) => emails.split(";")))
  )
    .map((email) => email.trim())
    .filter((email) => email != "");
}

export default {
  components: {
    "ls-modal": Modal,
    AddMemberStatusItem,
  },
  props: {
    memberService: {
      type: Object,
      required: true,
    },
    serviceItemId: {
      type: Number,
      required: true,
    },
    allowedRoles: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      saving: false,
      email: "",
      role: null,
      savingStatus: {},
    };
  },
  computed: {
    allowedToAdd() {
      let role = this.role;
      let emailAddresses = this.emailAddresses;
      return emailAddresses.length > 0 && role !== null;
    },
    roles() {
      let allowedRoles = this.allowedRoles;
      return ["owner", "editor", "participant"].filter((r) => allowedRoles.includes(r));
    },
    emailAddresses() {
      return findAllEmailAddresses(this.email);
    },
    allSavesCompleted() {
      let savingStatus = this.savingStatus;
      let emailAddresses = this.emailAddresses;

      return emailAddresses.map((email) => savingStatus[email]).every((v) => v !== undefined);
    },
    readyToClose() {
      let saving = this.saving;
      let allSavesCompleted = this.allSavesCompleted;

      if (!saving) {
        return true;
      }

      return allSavesCompleted;
    },
  },
  watch: {
    visible(isVisible) {
      if (!isVisible) {
        this.email = "";
        this.savingStatus = {};
        this.role = null;
        this.saving = false;
      }
    },
  },
  methods: {
    addMember(email) {
      let memberDetails = {
        email,
        role: this.role,
      };
      this.memberService
        .addMember(this.serviceItemId, memberDetails)
        .then((response) => {
          if (response.status == 201) {
            this.savingStatus[email] = "added";
            this.$emit("added-member", response.data);
          } else {
            this.savingStatus[email] = "error";
          }
        })
        .catch(() => {
          this.savingStatus[email] = "error";
        });
    },
    handleAddMembers() {
      this.savingStatus = {};
      this.saving = true;

      this.emailAddresses.forEach((email) => {
        this.addMember(email);
      });
    },
  },
};
</script>
