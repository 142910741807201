import axios from "axios";
import store from "~/store";
import router from "~/router";

export const services = {
  url: "/users",

  registerEmail(data) {
    return axios.post(`${this.url}/create-account/`, data);
  },

  confirmRegistration(data) {
    return axios.put(`${this.url}/verify-account/`, data);
  },

  register(data) {
    return axios.post(this.url + "/register/", data);
  },

  inviteRegister(data) {
    return axios.post(this.url + "/invite-register", data);
  },

  validateInviteToken(token) {
    return axios.post(this.url + "/validate-invite-token/" + token);
  },

  login(credentials) {
    return axios.post(this.url + "/login/", credentials);
  },

  logout() {
    return axios.post(this.url + "/logout/");
  },

  backendLoginUrl(service, { destination, params } = {}) {
    let suffix = "";
    let joiner = "?";
    if (destination) {
      suffix = encodeURIComponent(`${joiner}next=${destination}`);
      joiner = "&";
    }
    if (params) {
      suffix += `${joiner}${new URLSearchParams(params)}`;
    }

    return `/auth/login/${service}/?next=/auth/success/${suffix}`;
  },

  loginSuccess() {
    const loginSuccessUrl = "/users/login/success/";
    return axios.post(loginSuccessUrl);
  },

  ensureActiveSession() {
    const jwtToSessionURL = "/api/jwt-to-session/";
    return axios.get(jwtToSessionURL);
  },

  forgotPassword(data) {
    /* We create a new axios instance because our interceptor is adding an authentication header to each request
     * For public endpoints this isn't required and causes a 401 error when used with the third-party
     * django_rest_passwordreset library
     */
    const axiosNoAuthHeader = axios.create();
    return axiosNoAuthHeader.post(this.url + "/password-reset/", data);
  },

  reset(data) {
    const axiosNoAuthHeader = axios.create();
    return axiosNoAuthHeader.post(this.url + "/password-reset/confirm/", data);
  },

  refresh() {
    return axios.post(this.url + "/refresh/", {
      refresh: store.state.refreshToken,
    });
  },

  authorizationHeader() {
    return `Bearer ${store.state.token}`;
  },

  clearLocal() {
    localStorage.clear();
  },

  confirmRedirect(code) {
    return axios.post(`/api/join-template-link/${code}/`);
  },

  /**
   * Create a custom axios instance with additional custom headers.
   * @param {boolean} isGuest - The value of `X-Guest` header
   */
  createCustomHeaderAxios(isGuest = false, refreshOn401 = false) {
    let customAxios = axios.create();

    customAxios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    customAxios.defaults.xsrfCookieName = "csrftoken";
    customAxios.interceptors.request.use(
      (config) => {
        config.headers = {
          Authorization: this.authorizationHeader(),
          "X-Guest": isGuest,
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-TOKEN": document.querySelector('input[name="csrfmiddlewaretoken"]').value,
        };
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    if (refreshOn401) {
      customAxios.interceptors.response.use(undefined, (error) => {
        let errorResponse = error.response;

        if (errorResponse.status === 401 && errorResponse.config.url.includes("/users/refresh/")) {
          store.dispatch("hideLoader");
          store.dispatch("logout");
          router.push({ name: "Login" });
          return Promise.resolve(errorResponse);
        }

        if (
          errorResponse.status === 401 &&
          errorResponse.data.code === "token_not_valid" &&
          errorResponse.config &&
          !errorResponse.config.__isRetryRequest
        ) {
          return new Promise((resolve, reject) => {
            this.refresh()
              .then(({ data: result }) => {
                if (result.token) {
                  store.dispatch("updateToken", result.token);
                  errorResponse.config.__isRetryRequest = true;
                  errorResponse.config.headers.Authorization = this.authorizationHeader();
                  resolve(axios(errorResponse.config));
                }
              })
              .catch((errored) => {
                reject(errored);
              });
          });
        }

        return Promise.reject(errorResponse);
      });
    }

    return customAxios;
  },
};

export default services;
