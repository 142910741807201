<template>
  <div>
    <h1 class="title dashboard-title new-serif bold">Dashboard</h1>
    <collections-overview/>
    <templates-overview/>
  </div>
</template>

<script>
import CollectionsOverview from "modules/collection/components/CollectionsOverview.vue";
import TemplatesOverview from "modules/template/views/TemplatesOverview.vue";

export default {
  name: "DashboardHome",

  components: {
    CollectionsOverview,
    TemplatesOverview,
  },
};
</script>
