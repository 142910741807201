<template>
  <div v-if="dataReady" class="draft-container flex-fill">
    <submit-view v-if="action === 'submit'"></submit-view>
    <send-email-view v-if="action === 'send-email'"></send-email-view>
    <submit-example-view v-if="action === 'submit-example'"></submit-example-view>
    <div></div>
  </div>
</template>

<script>
import { ref } from "vue";

import { useStoreData } from "../composables/useStoreData";
import SendEmailView from "../components/FinalizeSendEmailView.vue";
import SubmitExampleView from "../components/FinalizeSubmitExampleView.vue";
import SubmitView from "../components/FinalizeSubmitSubmissionView.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "Drafting",

  components: {
    SendEmailView,
    SubmitExampleView,
    SubmitView,
  },

  props: {
    templateId: {
      type: [Number, String],
      required: true,
    },

    slug: {
      type: String,
      default: "",
    },

    action: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();
    const store = useStore();
    const dataReady = ref(false);

    const { loadDraft } = useStoreData();

    if (props.slug) {
      loadDraft(props.slug).then(() => (dataReady.value = true));
    } else {
      store.dispatch("showSnackbar", "❌ Invalid URL");

      router.push({
        name: "Template",
        params: { templateId: props.templateId },
      });
    }

    return { dataReady };
  },
};
</script>
