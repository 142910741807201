import AOS from "aos";
import { createApp } from "vue";
import { provide, h } from "vue";
import VueSecureHTML from "vue-html-secure";

import { abilitiesPlugin, Can } from "@casl/vue";

import App from "./App.vue";
import "./bootstrap";
import Alert from "./core/Alert.vue";
import Burger from "./core/Burger.vue";
import Header from "./core/Header.vue";
import Footer from "./core/Footer.vue";
import ExternalHeader from "./core/ExternalHeader.vue";
import ScreenLoader from "./core/ScreenLoader.vue";
import SlideOutSideNav from "./core/SlideOutSideNav.vue";
import Snackbar from "./core/Snackbar.vue";
import router from "./router";
import store, { StoreSymbol } from "./store";
import ability from "core/abilities";
import focus from "./shared/directives/focus";
import focusTrap from "./shared/directives/focusTrap";

import "../sass/app.scss";
import "aos/dist/aos.css";


const app = createApp({
  setup() {
    provide(StoreSymbol, store);
  },
  el: "#app",
  render: () => h(App),
});

app.use(store);
app.use(router);
app.use(abilitiesPlugin, ability, {useGlobalProperties: true});
app.use(VueSecureHTML);
app.component("Alert", Alert);
app.component("Burger", Burger);
app.component("EtHeader", Header);
app.component("EtFooter", Footer);
app.component("ExternalHeader", ExternalHeader);
app.component("ScreenLoader", ScreenLoader);
app.component("SlideOutSideNav", SlideOutSideNav);
app.component("Snackbar", Snackbar);
app.component("Can", Can);
app.directive("focus", focus);
app.directive("focusTrap", focusTrap);

app.mount("#app")

AOS.init();
