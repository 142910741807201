<template>
  <div ref="submissionDetailModalTrigger" tabindex="-1" @click="viewFullSubmission()" class="table-row clickable" aria-haspopup="dialog">
    <div class="row-item smaller bold">{{ submission.username }}</div>
    <div class="row-item content">
      <div class="submission-subject bold">{{ submission.title }}</div>
      <div v-html="truncatedSubmission"></div>
    </div>
    <div class="row-item smaller">{{ submissionCreatedAt }}</div>
    <div class="row-item smaller table-actions stacked">
      <router-link
        v-if="submission.email_slug"
        :to="{ name: 'GuestFeedback', params: { slug: submission.email_slug } }"
        v-slot="{ navigate }"
        custom
      >
        <button
          :aria-label="'Peer Feedback ' + submission.id"
          class="button-link submission-feedback"
          @click="navigate"
          @keypress.enter="navigate"
          role="link"
        >
          View Peer Feedback
        </button>
      </router-link>
      <router-link
        :to="{ name: 'SubmissionDetail', params: { slug: submission.slug } }"
        v-slot="{ navigate }"
        custom
      >
        <button
          :aria-label="'Tagged Draft ' + submission.id"
          class="button-link submission-feedback"
          @click="navigate"
          @keypress.enter="navigate"
          role="link"
        >
          View Tagged Draft
        </button>
      </router-link>
      <Can I="delete_submission" :this="currentTemplate">
        <button
          @click.prevent.stop="deleteSubmission()"
          class="button-link submission-feedback delete"
          role="button"
        >
          Delete
        </button>
      </Can>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { truncateHTML } from "shared/utils";
import { mapGetters } from "vuex";

export default {
  name: "SubmissionListItem",

  props: {
    submission: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showConfirmDelete: false,
    };
  },

  computed: {
    ...mapGetters("template", { getTemplateById: "getTemplateById" }),

    submissionCreatedAt() {
      return dayjs(this.submission.created_at).format("MM/DD/YYYY - h:mma");
    },

    truncatedSubmission() {
      return `${truncateHTML(this.submission.content)}...`;
    },

    currentTemplate() {
      return this.getTemplateById(this.submission.template_id);
    },
  },

  methods: {
    viewFullSubmission() {
      this.$emit("view-full-submission", { ...this.submission });
    },

    deleteSubmission() {
      this.$emit("delete-submission", this.submission);
    },
  },
};
</script>
