<template>
  <section
    role="region"
    class="example-view"
    aria-labelledby="example-section-heading"
    aria-roledescription="carousel"
    id="examples-panel"
  >
    <template v-if="examplesCount > 0">
      <div class="draft-view__header">
        <div class="header-container">
          <h2 id="example-section-heading" class="text-med new-serif bold">Examples</h2>
          <span class="words">{{ examplePosition }}</span>
          <div class="links">
            <button
              class="button narrow"
              aria-controls="example-items"
              @click.prevent="showPreviousExample"
            >
              Previous
            </button>
            <button
              class="button narrow"
              aria-controls="example-items"
              @click.prevent="showNextExample"
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div class="panel">
        <p class="text-xsmall instructions">
          Examples are to be used for reflection, not direct copying.
        </p>
        <div id="example-items" class="draft-content" aria-live="polite" aria-atomic="false">
          <email-content
            :aria-label="examplePosition"
            role="group"
            aria-roledescription="slide"
            ref="content"
            :email="example"
            :displayed-tags="tagsToBeDisplayed"
            @focus="$emit('focus')"
          >
          </email-content>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="draft-view__header">
        <div class="header-container">
          <h2 id="example-section-heading" class="text-med new-serif bold">Examples</h2>
          <span class="words">{{ examplesCount }} Total</span>
        </div>
      </div>
      <div class="panel">
        <p class="text-xsmall instructions">
          Examples are to be used for reflection, not for direct copying.
        </p>
        <div class="draft-view-subject empty">No Examples Found</div>
        <div class="draft-content empty">
          Once examples are added by the template owner, they will display here.
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import EmailContent from "shared/components/email/EmailContent.vue";

import DraftsService from "services/drafts";

export default {
  name: "ExampleView",

  components: {
    EmailContent,
  },

  data() {
    return {
      isHidden: false,
      userDrafts: [],
      exampleIndex: 0,
      example: {
        id: null,
        user_id: null,
        template_id: null,
        subject: "",
        content: "",
        created_at: null,
        updated_at: null,
      },
    };
  },

  computed: {
    ...mapState("template", { template: "currentTemplate" }),
    ...mapGetters("template", { examples: "approvedExamples" }),
    ...mapGetters("drafting", { getExampleTagsToBeDisplayed: "getExampleTagsToBeDisplayed" }),

    examplesCount() {
      const examples = this.examples;
      return examples.length;
    },

    examplePosition() {
      return `${this.exampleIndex + 1} of ${this.examplesCount}`;
    },

    savedByUser() {
      if (!this.template || !this.template.emails) {
        return;
      }

      const draftIds = this.userDrafts.filter((draft) => draft.source_id === this.example.id);
      return draftIds.length > 0;
    },

    tagsToBeDisplayed() {
      return this.getExampleTagsToBeDisplayed(this.example);
    },
  },

  watch: {
    template: {
      handler: async function (newTemplate, oldTemplate) {
        if (newTemplate && (newTemplate.id !== oldTemplate?.id || this.examples.length === 0)) {
          await this.getExamples(newTemplate.id);
        }
        if (this.examples.length > 0) {
          this.example = this.examples[0];
        }
      },
      immediate: true,
    },
    examples: {
      handler: function () {
        if (this.examples.length > 0) {
          this.example = this.examples[0];
        }
      },
      deep: true,
      immediate: true,
    },
  },

  async created() {
    await this.getDrafts();

    if (this.template && this.examplesCount === 0) {
      await this.getExamples(this.template.id);
    }
  },

  methods: {
    ...mapActions("template", {
      getExamples: "getExamples",
      favoriteExampleAction: "favoriteExample",
    }),
    ...mapActions("drafting", { recordExampleClicked: "recordEmailExampleClicked" }),

    async getDrafts() {
      const response = await DraftsService.list(this.template.id);
      this.userDrafts = response.data;
    },

    async favoriteExample() {
      await this.favoriteExampleAction(this.example);
      this.example = this.examples[this.exampleIndex];
    },

    showPreviousExample() {
      this.exampleIndex = this.exampleIndex > 0 ? this.exampleIndex - 1 : this.examples.length - 1;
      this.example = this.examples[this.exampleIndex];
      this.recordExampleClicked(this.example);
    },

    showNextExample() {
      this.exampleIndex = this.examples.length - 1 > this.exampleIndex ? this.exampleIndex + 1 : 0;
      this.example = this.examples[this.exampleIndex];
      this.recordExampleClicked(this.example);
    },
  },
};
</script>
