<template>
  <div class="comment-item" v-if="comment.is_resolved">
    <span><strong>Resolved comment</strong></span>
    <button class="button-link" v-if="canResolve" @click="unresolveFeedback">Unresolve</button>
  </div>
  <div class="comment-item" v-else>
    <div class="comment-item-content">
      <div class="top-section">
        <div class="flex vc">
          <img alt="User Image" class="comment-image" v-if="comment.user_picture" :src="comment.user_picture" />
          <img alt="User Default Image" class="comment-image" v-else src="/static/images/default-user-image.png" />
          <span class="name">
            <strong>{{ comment.user_name }}</strong>
            {{ commentCreatedAt }}
          </span>
          <div class="has-hover-text" v-if="outdatedComment">
            <button aria-label="Applies to a previous draft version" class="previous-draft-version hover-text-icon"></button>
            <span class="hover-text">Applies to a previous draft version</span>
          </div>
          <div class="has-hover-text resolve">
            <button aria-label="Resolve Comment" class="resolve-button hover-text-icon" v-if="canResolve" @click="resolveFeedback"></button>
            <span class="hover-text">Resolve Comment</span>
          </div>
        </div>
      </div>
      <p class="comment-message">
        {{ comment.message }}
      </p>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { computed } from "vue";
import { useStore } from 'vuex';

export default {
  name: "CommentItem",

  props: {
    comment: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();
    const commentCreatedAt = computed(() =>
      dayjs(props.comment.created_at).format("MMM, D YYYY [at] h:mma")
    );

    const canResolve = computed(
      () => store.state.user.id === store.state.drafting.currentEmail.user
    );

    const resolveFeedback = () => {
      store.dispatch("drafting/setEmailFeedbackResolution", {
        feedbackId: props.comment.id,
        isResolved: true,
      });
    };

    const unresolveFeedback = () => {
      store.dispatch("drafting/setEmailFeedbackResolution", {
        feedbackId: props.comment.id,
        isResolved: false,
      });
    };

    return {
      outdatedComment: false,
      commentCreatedAt,
      canResolve,
      resolveFeedback,
      unresolveFeedback,
    };
  },
};
</script>
