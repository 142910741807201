<template>
  <div class="tab-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 50">
      <rect
        class="additional-example with-stroke"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-width="4"
        stroke="#596573"
        fill="none"
        height="40"
        width="30"
        x="10"
        rx="4"
        ry="4"
        y="5"
      />
      <rect
        stroke-linejoin="round"
        stroke-linecap="round"
        class="with-stroke"
        stroke-width="4"
        stroke="#596573"
        fill="none"
        height="40"
        width="30"
        x="10"
        rx="4"
        ry="4"
        y="5"
      />
      <g>
        <rect
          class="with-stroke with-fill"
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="4"
          stroke="#596573"
          height="40"
          width="30"
          x="10"
          rx="4"
          ry="4"
          y="5"
        />
        <polygon
          points="25 20 21 15.71 17 20 17 19.99 17 5 25 5 25 20"
          class="flag with-stroke with-solid-fill"
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="2"
          stroke="#596573"
          fill="#596573"
        />
      </g>
    </svg>
  </div>
</template>
