<template>
  <main id="main" class="container-min flex column admin-wrapper">
    <div class="breadcrumb-navigation">
      <router-link class="button-link" :to="{ name: 'Dashboard' }">Dashboard</router-link>
      <img alt="" src="/static/images/icons/arrow-dark-blue.png" />
      <router-link class="button-link" :to="{ name: 'AdminHome' }">Site Administration</router-link>
      <img alt="" src="/static/images/icons/arrow-dark-blue.png" />
      <p class="current-location">Deployments for {{ registration.name }}</p>
    </div>
    <div class="admin-header">
      <h1 class="title new-serif bold">Deployments for {{ registration.name }}</h1>
    </div>
    <div class="admin-content">
      <div class="admin-item details-container">
        <header>
          <div class="header-container">
            <img alt="" src="/static/images/icons/lti-registration.svg" />
            <h2 class="text-med new-serif bold">Deployments</h2>
          </div>
          <router-link class="button" :to="{ name: 'AddLtiDeployment' }">
            Add Deployment
          </router-link>
        </header>
        <table class="card-container" v-if="registration.deployments.length !== 0">
          <thead>
            <tr class="table-header">
              <th class="row-item">Deployment ID</th>
              <th class="row-item">Status</th>
              <th class="row-item">Actions</th>
            </tr>
          </thead>
          <tbody class="table-content">
            <tr
              class="table-row"
              v-for="deployment in registration.deployments"
              :key="deployment.id"
            >
              <td class="row-item">{{ deployment.deployment_id }}</td>
              <td class="row-item">
                <div
                  class="status-tag"
                  :class="{ active: deployment.is_active }"
                >
                  {{ deployment.is_active ? 'Active': 'Inactive' }}
                </div>
              </td>
              <td class="row-item table-actions stacked">
                <router-link
                  class="button-link"
                  :to="{ name: 'EditLtiDeployment', params: { id: deployment.id } }"
                >
                  Edit
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="card-container not-table empty-text" v-else>
          No Deployments Added
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const route = useRoute();
    const store = useStore();

    const getRegistrationById = store.getters["admin/getRegistrationById"];
    const id = parseInt(route.params.registrationId);
    const registration = getRegistrationById(id);

    return { registration };
  },
});
</script>
