<template>
  <modal :visible="visible" @close="close">
    <template #content>
      <div>
        <h2 class="heading" id="dialog_label">
          {{ title }}
        </h2>
        <p v-if="errorMessage" class="error-text-lighter" aria-live="polite">
          {{ errorMessage }}
        </p>
        <div class="flex vc fs padding-sides">
          <label for="current-password">Current Password</label>
          <input
            id="current-password"
            class="input margin-right"
            :class="{ error: v$.currentPassword.$error }"
            placeholder="Enter password here..."
            v-model="currentPassword"
            @input="v$.currentPassword.$touch"
            type="password"
          />
        </div>
        <p v-if="v$.currentPassword.$dirty && !v$.currentPassword.required">
          This field is required.
        </p>
        <div class="flex vc fs padding-sides margin-top">
          <label for="new-password">New Password</label>
          <input
            id="new-password"
            type="password"
            class="input margin-right"
            :class="{ error: v$.newPassword.$error }"
            placeholder="Enter password here..."
            v-model="newPassword"
            @input="v$.newPassword.$touch"
          />
        </div>
        <p v-if="v$.newPassword.$dirty && !v$.newPassword.required">This field is required.</p>
        <div class="flex vc fs padding-sides margin-top">
          <label for="confirm-password">Confirm Password</label>
          <input
            id="confirm-password"
            type="password"
            class="input margin-right"
            :class="{
              error: v$.passwordConfirmation.$error || v$.passwordConfirmation.$sameAsPassword,
            }"
            placeholder="Enter password here..."
            v-model="passwordConfirmation"
            @input="v$.passwordConfirmation.$touch"
          />
        </div>
        <template v-if="v$.passwordConfirmation.$dirty">
          <p v-if="!v$.passwordConfirmation.required">This field is required.</p>
          <p v-else-if="!v$.passwordConfirmation.sameAsPassword">Password must match.</p>
        </template>
      </div>
    </template>
    <template #footer>
      <div class="flex hc vc">
        <button
          class="button full primary fill"
          :disabled="hasInvalidField"
          @click="updatePassword"
        >
          {{ buttonTitle }}
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, sameAs } from "@vuelidate/validators";

import UserService from "services/user";

import Modal from "shared/components/Modal.vue";

export default {
  name: "ResetPasswordModal",
  components: { Modal },
  setup () {
    return { v$: useVuelidate() };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: "Reset Password",
      errorMessage: null,
      buttonTitle: "Update",
      currentPassword: "",
      newPassword: "",
      passwordConfirmation: "",
    };
  },
  computed: {
    ...mapState(["user", "token"]),
    hasInvalidField() {
      return (
        this.v$.currentPassword.$invalid ||
        this.v$.newPassword.$invalid ||
        this.v$.passwordConfirmation.$invalid
      );
    },
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    close() {
      this.$emit("close");
      this.errorMessage = "";
      this.v$.$reset.call();
    },
    updatePassword() {
      this.errorMessage = "";
      UserService.updatePassword({
        current_password: this.currentPassword,
        new_password: this.newPassword,
        password_confirmation: this.passwordConfirmation,
      })
        .then((response) => {
          if (response.data.success) {
            this.close();
            this.showSnackbar("Successfully updated your password!");
          } else {
            this.errorMessage = response.data.error;
          }
        })
        .catch((err) => {
          this.errorMessage = err.data.error;
        });
    },
  },
  watch: {
    visible(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.currentPassword = "";
        this.newPassword = "";
        this.passwordConfirmation = "";
      }
    },
  },
  validations: {
    currentPassword: {
      required,
    },
    newPassword: {
      required,
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs("newPassword"),
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  display: inline-flex;
  flex: 2;
}
input {
  flex: 4;
  &.error {
    border-color: #b93c5f;
  }
}
.warp {
  flex-wrap: wrap;
}
</style>
