import dayjs from "dayjs";
import axios from "axios";

import store from "./store";
import router from "./router";

import AuthService from "./services/auth";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

// Add the authorization header on requests
axios.interceptors.request.use(
  (config) => {
    config.headers = {
      Authorization: AuthService.authorizationHeader(),
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document.querySelector('input[name="csrfmiddlewaretoken"]').value,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Refresh JWT if 401 is returned
axios.interceptors.response.use(undefined, (error) => {
  let errorResponse = error.response;

  if (errorResponse.status === 401 && errorResponse.config.url.includes("/users/refresh/")) {
    store.dispatch("hideLoader");
    store.dispatch("logout");
    router.push({ name: "Login" });
    return Promise.resolve(errorResponse);
  }

  if (
    errorResponse.status === 401 &&
    errorResponse.data.code === "token_not_valid" &&
    errorResponse.config &&
    !errorResponse.config.__isRetryRequest
  ) {
    return new Promise((resolve, reject) => {
      AuthService.refresh()
        .then(({ data: result }) => {
          if (result.token) {
            store.dispatch("updateToken", result.token);
            errorResponse.config.__isRetryRequest = true;
            errorResponse.config.headers.Authorization = AuthService.authorizationHeader();
            resolve(axios(errorResponse.config));
          }
        })
        .catch((errored) => {
          reject(errored);
        });
    });
  }

  return Promise.reject(errorResponse);
});

store.dispatch("init");
