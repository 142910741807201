<template>
  <div class="tab-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 50 50">
      <circle
        class="with-stroke with-solid-fill"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-width="4"
        stroke="#596573"
        fill="none"
        r="1.25"
        cx="25"
        cy="25"
      />
      <path
        d="m30,25c0,2.76-2.24,5-5,5s-5-2.24-5-5,2.24-5,5-5h0c2.76,0,5,2.24,5,5Z"
        stroke-linejoin="round"
        stroke-linecap="round"
        class="with-stroke"
        stroke-width="4"
        stroke="#596573"
        fill="none"
      />
      <path
        d="m38.35,30.42c-.5,1.11-.26,2.42.6,3.29l.11.11c1.42,1.41,1.42,3.71,0,5.12s-3.73,1.41-5.15,0l-.11-.11c-1.2-1.15-3.12-1.12-4.28.07-.54.55-.84,1.29-.85,2.06v.32c.06,2-1.52,3.66-3.53,3.72-2.01.06-3.68-1.51-3.74-3.51,0-.07,0-.14,0-.22v-.16c-.09-1.73-1.58-3.05-3.31-2.95-.73.04-1.42.33-1.96.82l-.11.11c-1.42,1.41-3.73,1.41-5.15,0-1.42-1.41-1.42-3.71,0-5.12h0l.11-.11c1.16-1.19,1.13-3.1-.07-4.25-.56-.53-1.3-.84-2.07-.84h-.32c-2.01-.06-3.58-1.73-3.53-3.72.06-1.91,1.6-3.45,3.53-3.51h.16c1.73-.09,3.06-1.57,2.97-3.3-.04-.72-.33-1.41-.82-1.95l-.11-.11c-1.42-1.41-1.42-3.71,0-5.12,1.42-1.41,3.73-1.41,5.15,0l.11.11c.88.85,2.19,1.09,3.31.6h.14c1.1-.47,1.81-1.54,1.82-2.73v-.32c-.06-2,1.52-3.66,3.53-3.72,2.01-.06,3.68,1.51,3.74,3.51,0,.07,0,.14,0,.22v.16c.02,1.66,1.38,2.99,3.05,2.98.77,0,1.51-.31,2.07-.84l.11-.11c1.42-1.41,3.73-1.41,5.15,0,1.42,1.41,1.42,3.71,0,5.12l-.11.11c-.86.87-1.09,2.17-.6,3.29v.14c.47,1.09,1.55,1.8,2.74,1.81h.32c2.01-.06,3.68,1.51,3.74,3.51.06,2-1.52,3.66-3.53,3.72-.07,0-.14,0-.22,0h-.16c-1.2,0-2.27.72-2.74,1.81Z"
        stroke-linejoin="round"
        stroke-linecap="round"
        class="with-stroke"
        stroke-width="4"
        stroke="#596573"
        fill="none"
      />
    </svg>
  </div>
</template>
