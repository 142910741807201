<template>
  <div class="tab-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 50 50">
      <g class="group-1">
        <rect
          class="with-stroke with-solid-fill"
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="4"
          stroke="#596573"
          fill="#596573"
          height="10"
          width="30"
          x="10"
          y="30"
          rx="5"
          ry="5"
        />
        <circle
          class="with-stroke with-fill"
          stroke-miterlimit="10"
          stroke="#596573"
          stroke-width="4"
          cy="16.25"
          r="6.25"
          cx="25"
        />
      </g>
      <g class="group-2">
        <rect
          class="with-stroke with-fill"
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="4"
          stroke="#596573"
          height="10"
          width="30"
          x="10"
          y="30"
          rx="5"
          ry="5"
        />
        <circle
          class="with-stroke with-solid-fill"
          stroke-miterlimit="10"
          stroke-width="4"
          stroke="#596573"
          fill="#596573"
          cy="16.25"
          r="6.25"
          cx="25"
        />
      </g>
    </svg>
  </div>
</template>
