<template>
  <div class="container external-page layout-not-logged-in viewing-modes">
    <et-header class="external" />
    <main id="main" class="container">
      <div class="wide-container">
        <div>
          <h1 class="new-serif bold text-highlight-primary">
            Hello There! Please Select a Viewing Mode
          </h1>
          <p class="text-small">
            Please select the viewing mode you most closely identify with & how you intend to use
            Lettersmith.
            <button
              @click="showViewingModesModal = true"
              ref="viewingModesModalTrigger"
              class="button-link"
              aria-label="Read more about Lettersmith's viewing modes"
            >
              Read more
            </button>
            about Lettersmith's viewing modes.
          </p>
        </div>
        <div class="viewing-modes-container">
          <div class="mode-and-tooltip">
            <div
              class="viewing-mode"
              role="radio"
              :aria-checked="learnerModeSelected ? 'true' : 'false'"
              aria-labelledby="viewing-mode-radio1-label"
              data-value="Writer"
              :class="{ active: learnerModeSelected }"
              @keyup.enter="selectLearner"
              @click="selectLearner"
              tabindex="0"
              aria-label="Writer Mode"
            >
              <div class="viewing-mode-header">
                <h2 id="viewing-mode-radio1-label">Writer</h2>
                <div
                  v-if="learnerModeSelected"
                  aria-label="Checkmark icon"
                  class="selected-icon button-icon"
                ></div>
              </div>
              <p>I'm using Lettersmith to improve my writing.</p>
            </div>
            <div class="help-text">
              <p class="description-text">
                Note: Please read more about the <b>Writer</b> mode if you need assistance:
              </p>
              <button
                class="button-icon tooltip-icon white-hover"
                @click.stop="showLearnerModal = true"
                ref="learnerModalTrigger"
                aria-label="Open writer tooltip"
              ></button>
            </div>
          </div>
          <div class="mode-and-tooltip">
            <div
              class="viewing-mode"
              role="radio"
              :aria-checked="adminModeSelected ? 'true' : 'false'"
              aria-labelledby="viewing-mode-radio2-label"
              data-value="Admin"
              :class="{ active: adminModeSelected }"
              @keyup.enter="selectAdmin"
              @click="selectAdmin"
              tabindex="0"
              aria-label="Admin Mode"
            >
              <div class="viewing-mode-header">
                <h2 id="viewing-mode-radio2-label">Admin</h2>
                <div
                  v-if="adminModeSelected"
                  aria-label="Checkmark icon"
                  class="selected-icon button-icon"
                ></div>
              </div>
              <p>I'm using Lettersmith to help learners improve their writing.</p>
            </div>
            <div class="help-text">
              <p class="description-text">
                Note: Please read more about the <b>Admin</b> mode if you need assistance:
              </p>
              <button
                class="button-icon tooltip-icon white-hover"
                @click.stop="showAdminModal = true"
                ref="adminModalTrigger"
                aria-label="Open admin tooltip"
              ></button>
            </div>
          </div>
        </div>
        <div class="help-text">
          <div class="information-icon button-icon no-hover"></div>
          <p class="description-text">
            Note: The Viewing Mode can be updated at a later date within your Account Page.
          </p>
        </div>
        <button :disabled="disabled" class="button" @click="updateViewingMode">Continue</button>
      </div>
    </main>
    <viewing-modes-modal-details
      v-if="showViewingModesModal"
      @close="hideViewingModesModalAndRefocus"
    ></viewing-modes-modal-details>
    <writer-modal-details
      v-if="showLearnerModal"
      @close="hideLearnerModalAndRefocus"
    ></writer-modal-details>
    <admin-modal-details
      v-if="showAdminModal"
      @close="hideAdminModalAndRefocus"
    ></admin-modal-details>
  </div>
</template>

<script>
import ViewingModesModalDetails from "./ViewingModesModalDetails.vue";
import WriterModalDetails from "./WriterModalDetails.vue";
import AdminModalDetails from "./AdminModalDetails.vue";
import UserService from "services/user";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    ViewingModesModalDetails,
    WriterModalDetails,
    AdminModalDetails,
  },
  props: {
    templateId: {
      type: [Number, String],
      default: null,
    },
    collectionId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      learnerModeSelected: false,
      adminModeSelected: false,
      disabled: true,
      showViewingModesModal: false,
      showLearnerModal: false,
      showAdminModal: false,
      errorMessage: "",
      modeSelected: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    successUrl() {
      let templateId = this.templateId;
      let collectionId = this.collectionId;
      let destination = { name: "Dashboard" };

      if (templateId) {
        destination = { name: "Template", params: { templateId } };
      } else if (collectionId) {
        destination = { name: "Collection", params: { collectionId } };
      } 
      let { href } = this.$router.resolve(destination);  
      return href;
    },
  },
  methods: {
    ...mapActions(["updateUserViewingMode"]),
    selectLearner() {
      this.learnerModeSelected = true;
      this.adminModeSelected = false;
      this.disabled = false;
    },
    selectAdmin() {
      this.learnerModeSelected = false;
      this.adminModeSelected = true;
      this.disabled = false;
    },
    hideViewingModesModalAndRefocus() {
      this.showViewingModesModal = false;
      this.$refs.viewingModesModalTrigger.focus();
    },
    hideLearnerModalAndRefocus() {
      this.showLearnerModal = false;
      this.$refs.learnerModalTrigger.focus();
    },
    hideAdminModalAndRefocus() {
      this.showAdminModal = false;
      this.$refs.adminModalTrigger.focus();
    },
    updateViewingMode() {
      UserService.updateViewingMode({
        is_writer: this.learnerModeSelected,
      })
        .then((response) => {
          if (response.data.success) {
            this.updateUserViewingMode(response.data.data.is_writer);
            this.modeSelected = true;
            this.$router.push(this.successUrl);
          } else {
            this.errorMessage = response.data.error;
          }
        })
        .catch((err) => {
          this.errorMessage = err.data.error;
        });
    },
  },
};
</script>

