<template>
  <div class="container external-page layout-not-logged-in">
    <et-header class="external"></et-header>
    <main id="main" class="login-page-wrapper container">
      <div class="narrow-container">
        <h1 class="serif text-large text-highlight-primary">Login to start writing.</h1>
        <form class="text-center" action="" @submit.prevent="submitLogin">
          <label for="email" class="visually-hidden">Email</label>
          <input
            id="email"
            class="input"
            placeholder="Email address"
            v-model="loginForm.email"
            type="email"
            autocomplete="email"
          />
          <label for="password" class="visually-hidden">Password</label>
          <input
            id="password"
            type="password"
            class="input"
            placeholder="Password"
            v-model="loginForm.password"
          />
          <p v-if="errorMessage !== ''" class="error-text-lighter text-small" aria-live="polite">
            {{ errorMessage }}
          </p>
          <button class="button text-small" type="submit" :disabled="v$.$error">Login</button>
        </form>
        <div class="separator text-small">or</div>
        <a class="button-secondary text-small" :href="googleLoginUrl">
          Login with Google
        </a>
        <a class="button-secondary text-small um-login-btn" :href="umLoginUrl">
          <img
            class="um-logo"
            alt="university of michigan block m logo"
            src="/static/images/logos/um-mark.png"
          />
          Login with U-M
        </a>

        <router-link :to="{ name: 'Signup', query: $route.query }" class="button-link text-small blue-dark">
          Sign up for a Lettersmith Account
        </router-link>
        <br />
        <router-link :to="{ name: 'LoginForgotPassword' }" class="button-link text-small blue-dark">
          Reset Password
        </router-link>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import AuthService from "services/auth";

export default {
  props: {
    templateId: {
      type: [Number, String],
      default: null,
    },
    collectionId: {
      type: [Number, String],
      default: null,
    },
  },
  setup () {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      errorMessage: "",
      loginForm: {
        email: "",
        password: "",
      },
    };
  },

  computed: {
    ...mapState({
      routeAfterLogin: (state) => state.routeAfterLogin,
    }),
    ...mapState(["user"]),

    successUrl() {
      let templateId = this.templateId;
      let collectionId = this.collectionId;

      let destination = { name: "Dashboard" };

      if (templateId) {
        destination = { name: "Template", params: { templateId } };
      } else if (collectionId) {
        destination = { name: "Collection", params: { collectionId } };
      } else if (this.$route.query.next) {
        destination = { path: this.$route.query.next };
      }
      if (this.user) {
        if (this.user.is_writer == null ) {
          if (templateId) {
            destination = { name: "ViewingModeWithTemplate", params: { templateId } };
          } else if (collectionId) {
            destination = { name: "ViewingModeWithCollection", params: { collectionId } };
          } else {
            destination = { name: "LoginSelectViewingMode" }
          };
        };
      };
      let { href } = this.$router.resolve(destination);

      return href;
    },

    umLoginUrl() {
      return AuthService.backendLoginUrl("umich-oidc", { destination: this.successUrl });
    },

    googleLoginUrl() {
      return AuthService.backendLoginUrl(
        "google-openidconnect", { destination: this.successUrl }
      );
    },
  },

  methods: {
    ...mapActions(["login", "setRouteAfterLogin", "showSnackbar"]),

    submitLogin() {
      if (this.v$.$invalid) {
        return;
      }

      this.errorMessage = "";

      AuthService.login(this.loginForm)
        .then((response) => {
          let result = response.data;
          if (result.success) {
            this.login({
              token: result.token,
              refresh: result.refresh,
              user: result.user,
            });
            if (this.routeAfterLogin && !this.$route.query.next) {
              this.$router.push(this.routeAfterLogin);
            } else {
              this.$router.push(this.successUrl);
            }
            this.setRouteAfterLogin(null);
          } else {
            this.errorMessage = result.error;
          }
        })
        .catch((errorResponse) => {
          this.errorMessage = errorResponse.data.error ?? "Login error";
        });
    },
  },

  created() {
    this.$nextTick(function () {
      this.v$.$touch()
    });
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "LoginResetPassword") {
        vm.showSnackbar("Password updated successfully! Please login with your new password.");
      }
    });
  },

  validations: {
    loginForm: {
      email: {
        required,
        email,
      },

      password: {
        required,
      },
    },
  },
};
</script>

<style lang="scss"></style>
