<template>
  <footer class="external-section dark footer">
    <div class="dark-container">
      <div>
        <img
          src="/static/images/logos/ai-white.svg"
          alt="University of Michigan Center for Academic Innovation"
          class="ai-logo"
        />
        <div class="powered-by">
          <p>Powered by the</p>
          <a class="button-link yellow" href="http://ai.umich.edu/" target="_blank">Center for Academic Innovation</a>
        </div>
        <p>© The Regents of the University of Michigan</p>
      </div>
      <p class="links">
        <router-link class="button-link yellow" :to="{ name: 'Terms of Service' }">Terms of Service </router-link>
        <router-link class="button-link yellow" :to="{ name: 'Learning Analytics' }">Learning Analytics </router-link>
        <a class="button-link yellow"
          href="https://ai.umich.edu/academic-innovation-website-privacy-policy/#tools-privacy-policy"
          target="_blank"
        >
          Privacy Policy
        </a>
      </p>
    </div>
  </footer>
</template>
