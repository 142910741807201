<template>
  <section id="checklist-panel" aria-labelledby="checklist-heading" class="draft-checklist checklist">
    <div class="draft-view__header">
      <div class="header-container">
        <h2 id="checklist-heading" class="text-med new-serif bold">{{ checklistName }}</h2>
        <div class="tooltip-container">
          <button
            class="button-icon tooltip-icon"
            @click="showChecklistTooltip = true"
            aria-label="Open Checklist Tooltip"
          >
          </button>
          <checklist-tooltip
            v-if="showChecklistTooltip"
            @close="showChecklistTooltip = false"
          ></checklist-tooltip>
        </div>
      </div>
      <span class="text-xsmall error-text bold">* Required</span>
    </div>
    <div class="panel">
      <p class="text-xsmall instructions">
        <span class="bold">How to Tag:</span> Highlight your writing and then select the appropriate
        tag below to properly tag your draft.
      </p>
      <div id="checklist-panel-section" class="checklist-panel" v-if="groups">
        <checklist-group
          :id="`checklist-${group.id}`"
          v-for="group in groups"
          :key="group.title"
          :group="group"
        >
        </checklist-group>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

import ChecklistGroup from "shared/components/checklist/ChecklistGroup.vue";
import ChecklistTooltip from "shared/components/checklist/ChecklistTooltip.vue";

export default {
  name: "ChecklistView",

  components: {
    ChecklistGroup,
    ChecklistTooltip,
  },

  data() {
    return {
      showChecklistTooltip: false,
    };
  },

  mounted() {
    this.setScrollPosition();
  },

  computed: {
    ...mapState({
      items: (state) => state.template.checklistItems,
      groups: (state) => state.template.checklistGroups,
    }),

    ...mapState("template", {
      template: "currentTemplate",
    }),

    checklistName() {
      return this.template?.features?.checklist_name ?? "Checklist";
    },
  },

  methods: {
    setScrollPosition() {
      var el = document.querySelector(".draft-checklist");
      el.scrollTop = this.tagPosition;
    },
  },
};
</script>
