<template>
  <main id="main" v-if="storeEmail" class="draft-view-container">
    <editor-view-header>
      <template #editor-view-header-title>
        <slot name="title">My Draft</slot>
      </template>
      <template #editor-view-header-middle>
        <span class="words"> {{ draftWordCount }} Word{{ draftWordCount == 1  ? "" : "s" }} </span>
        <tag-switch
          :checked="isDisplayingAllTags"
          @toggle="setIsDisplayingAllTags"
        />
      </template>
      <template v-if="isFeedbackUrlVisible" #editor-view-header-end>
        <div class="draft-feedback-link">
          <button class="button-secondary narrow" @click="copyFeedbackUrl">Feedback Link</button>
        </div>
      </template>
    </editor-view-header>
    <div class="draft-view draft-content">
      <vue-quill-editor
        v-if="!isInGuestFeedback"
        :draft="email"
        :draft-element-id="draftElementId"
        :tags="draftTags"
        :displayed-tags="draftTagsToBeDisplayed"
        :subject-placeholder="subjectLinePlaceholder"
        :toBeDeletedTagIds="toBeDeletedTagIds"
        label="Draft content"
        placeholder="Type your draft here..."
        @text-change="onDraftTextChange"
        @selected="onDraftSelected"
        @focus="setIsTagging(false)"
        @blur="setIsTagging(false)"
        @tags-changed="updateDraftTags"
        @tags-deleted="removeDraftTags"
      />

      <email-content
        v-else
        :email="email"
        :displayed-tags="draftTagsToBeDisplayed"
        @focus="$emit('focus')"
      ></email-content>

      <slot name="footer-extra"></slot>
    </div>

    <div class="draft-view-footer">
      <div class="last-saved text-small" aria-live="polite">
        Last Saved: {{ lastUpdatedAt }}
        <i v-if="isUpdatingDraft" class="fas fa-sync fa-spin fa-fw"></i>
      </div>

      <div class="buttons flex">
        <slot name="buttons-content">
          <router-link class="button-secondary narrow" :to="{ name: 'TemplateDrafts', params: { templateId: currentTemplate.id } }">
            Return to Template
          </router-link>

          <disclosure-menu
            label="Finalize"
            :disabled="nextDisabled"
            v-if="!isInGuestFeedback"
          >
            <template #items>
              <disclosure-menu-link v-if="canSubmitSubmission" :to="submitRoute">
                Submit draft
              </disclosure-menu-link>
              <disclosure-menu-link v-if="canSendEmail" :to="sendEmailRoute">
                Send as email
              </disclosure-menu-link>
              <disclosure-menu-link v-if="canSubmitExample" :to="submitExampleRoute">
                Submit as example
              </disclosure-menu-link>
              <disclosure-menu-button @click="copyDraft">
                Copy to clipboard
              </disclosure-menu-button>
              <export-menu-button v-if="canExport" :token="token" :email-id="email.id" />
            </template>
          </disclosure-menu>
        </slot>
      </div>
    </div>
  </main>
</template>

<script>
import dayjs from "dayjs";
import { debounce } from "lodash";
import { ref, computed, watch } from "vue";

import { computeWordCount } from "libs/document";
import { copyTextToClipboard, copyElementById } from "shared/utils/copy";

import EmailContent from "shared/components/email/EmailContent.vue";
import TagSwitch from "shared/components/TagSwitch.vue";

import EditorViewHeader from "./EditorViewHeader.vue";
import DisclosureMenu from "shared/components/dropdowns/DisclosureMenu.vue";
import DisclosureMenuLink from "shared/components/dropdowns/DisclosureMenuLink.vue";
import DisclosureMenuButton from "shared/components/dropdowns/DisclosureMenuButton.vue";
import ExportMenuButton from "./ExportMenuButton.vue";
import VueQuillEditor from "shared/components/editors/QuillEditor.vue";
import { useStore } from "vuex";


export default {
  name: "EditorView",

  components: {
    EditorViewHeader,
    DisclosureMenu,
    DisclosureMenuLink,
    DisclosureMenuButton,
    ExportMenuButton,
    VueQuillEditor,
    EmailContent,
    TagSwitch,
  },

  props: {
    showFeedbackUrl: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, context) {
    const draftElementId = ref("quill-draft-editor");
    const email = ref(null);
    const isUpdatingDraft = ref(false);
    const toBeDeletedTagIds = ref([]);

    //mapped store states and methods
    const store = useStore();
    const token = computed(() => store.state.token);
    const isInGuestFeedback = computed(() => store.state.drafting.isInGuestFeedback);
    const storeEmail = computed(() => store.state.drafting.currentEmail);
    const draftTags = computed(() => store.state.drafting.currentDraftTags);
    const currentTemplate = computed(() => store.state.template.currentTemplate);
    const isDisplayingAllTags = computed(() => store.state.drafting.isDisplayingAllTags);

    const draftTagsToBeDisplayed = computed(() => store.getters["drafting/draftTagsToBeDisplayed"]);
    const allRequiredChecklistItemsAreTagged = computed(
      () => store.getters["drafting/allRequiredChecklistItemsAreTagged"]
    );

    // using setTimeout to schedule this method call after other event emission
    // e.g. `tag` event emission in ChecklistItem.vue
    const setIsTagging = (isTagging) =>
      setTimeout(() => {
        store.dispatch("drafting/setIsTagging", isTagging);
      });

    const setIsDisplayingAllTags = (value) =>
      store.dispatch("drafting/setIsDisplayingAllTags", value);

    // init local states
    email.value = storeEmail.value;

    // watchers
    watch(email, (newEmail) => updateEmail(newEmail), { deep: true, immediate: false });

    watch(storeEmail, (newStoreEmail) => {
      if (!email.value.id) {
        email.value = newStoreEmail;
        store.dispatch("showSnackbar", "Draft saved successfully!");
      }
    });

    // UI-related computed properties
    const subjectLinePlaceholder = computed(() =>
      currentTemplate.value.features.can_email ? "Subject" : "Title"
    );
    const feedbackUrl = computed(
      () =>
        `${location.protocol}//${location.hostname}${location.port ? ":" + location.port : ""}/f/${
          storeEmail.value.slug
        }`
    );
    const copyFeedbackUrl = () => {
      copyTextToClipboard(feedbackUrl.value);
      store.dispatch("showSnackbar", "Feedback link copied successfully!");
    };
    const isFeedbackUrlVisible = computed(() => {
      return !isInGuestFeedback.value && props.showFeedbackUrl;
    });

    const lastUpdatedAt = computed(() =>
      dayjs(storeEmail.value.updated_at).format("MM/DD/YYYY, h:mm:ss a")
    );
    const draftWordCount = computed(() => {
      const content = email?.value.content || storeEmail.value.content;
      return computeWordCount(content);
    });
    const nextDisabled = computed(() => {
      const wordCount = draftWordCount.value;
      const currentEmail = storeEmail.value;

      return (
        wordCount < 1 ||
        currentEmail.subject === "" ||
        currentEmail.id == null ||
        !allRequiredChecklistItemsAreTagged.value
      );
    });

    // draft-related methods
    const onDraftTextChange = ({ html, subject }) => {
      if (html) {
        email.value = { ...email.value, content: html };
      }
      if (subject) {
        email.value = { ...email.value, subject };
      }
      context.emit("input");
    };

    const updateOrSaveEmail = async function (draftUpdates) {
      await store.dispatch("drafting/updateOrSaveEmail", draftUpdates);
      isUpdatingDraft.value = false;
    };
    const debouncedUpdateEmail = debounce(updateOrSaveEmail, 1000);

    const updateEmail = (newEmail) => {
      // Only update draft when it's valid
      if (newEmail?.subject && newEmail?.content) {
        isUpdatingDraft.value = true;
        let emailUpdates = { subject: newEmail.subject, content: newEmail.content };
        debouncedUpdateEmail(emailUpdates);
      }
    };

    const updateDraftTags = debounce(async function (tagList) {
      await store.dispatch("drafting/updateTags", tagList);
    }, 1000);

    const removeDraftTags = (inputTagIds) => {
      const notDeletedTagIds = [];
      inputTagIds.map(async (tagId) => {
        try {
          await store.dispatch("drafting/removeTag", tagId);
        } catch (error) {
          notDeletedTagIds.push(tagId);
        }
      });
      toBeDeletedTagIds.value = notDeletedTagIds;
    };

    const onDraftSelected = (selection) => {
      if (selection.content) {
        setIsTagging(true);
        store.dispatch("drafting/setCurrentSelection", selection);
      }
    };

    // dropdown
    const canSubmitSubmission = computed(
      () => currentTemplate.value.features.can_submit_submission
    );
    const canSubmitExample = computed(() => currentTemplate.value.features.can_submit_example);
    const canSendEmail = computed(() => currentTemplate.value.features.can_email);
    const canExport = computed(() => currentTemplate.value.features.can_export);

    const copyDraft = () => {
      copyElementById(draftElementId.value);
      store.dispatch("showSnackbar", "Draft copied to clipboard successfully!");
    };

    const finalizeRoute = (action) => ({
      name: "Finalize",
      params: { slug: storeEmail.value.slug, action: action },
    });

    const submitRoute = finalizeRoute("submit");
    const sendEmailRoute = finalizeRoute("send-email");
    const submitExampleRoute = finalizeRoute("submit-example");

    return {
      email,
      draftElementId,
      draftTagsToBeDisplayed,
      draftTags,
      feedbackUrl,
      copyFeedbackUrl,
      storeEmail,
      currentTemplate,
      isUpdatingDraft,
      toBeDeletedTagIds,
      subjectLinePlaceholder,
      isFeedbackUrlVisible,
      lastUpdatedAt,
      draftWordCount,
      nextDisabled,
      isDisplayingAllTags,
      setIsDisplayingAllTags,
      onDraftTextChange,
      updateDraftTags,
      removeDraftTags,
      onDraftSelected,
      setIsTagging,
      isInGuestFeedback,
      canSubmitSubmission,
      canSendEmail,
      canSubmitExample,
      canExport,
      copyDraft,
      token,
      submitRoute,
      sendEmailRoute,
      submitExampleRoute,
    };
  },
};
</script>
