<template>
  <modal :visible="visible" :ex-large="true" @close="close" class="compose-email-modal">
    <template #content>
      <div>
        <h1 class="text-med">Compose Email</h1>
        <div class="modal-body">
          <p class="subject">
            Your email will be sent from {{ senderEmail }} and appear as a standard email. If you
            would like this email sent from an alternative address, please close the modal, copy
            your draft content, and paste your content into your email client.
          </p>
          <div class="modal-form-field">
            <div class="form-input" id="to-input">
              <label for="to" class="bold">To</label>
              <p class="description-text">Press the <b>"Enter"</b> key after typing each email address.</p>
              <span class="email-address-input">
                <multiselect
                  v-model="recipients"
                  :placeholder="''"
                  :options="emailAddressOptions"
                  :multiple="true"
                  :taggable="true"
                  @tag="addRecipient"
                >
                  <template #noOptions>Please input email.</template>
                  <template #caret></template>
                </multiselect>
                <button class="button" @click="toggleCc">Cc</button>
                <button class="button" @click="toggleBcc">Bcc</button>
              </span>

              <span v-for="(recipient, index) in recipients" :key="index">
                <p
                  class="error-text"
                  v-if="v$.recipientItems.$each.$response.$errors[index].item.length"
                  aria-live="polite"
                >
                  {{ recipient }} is not a valid email
                </p>
              </span>
            </div>
            <div v-if="showCc" class="form-input" id="cc-input">
              <label for="cc" class="bold">Cc</label>
              <multiselect
                v-model="cc"
                :placeholder="''"
                :options="emailAddressOptions"
                :multiple="true"
                :taggable="true"
                @tag="addCc"
              >
                <template #noOptions>Please input email.</template>
              </multiselect>

              <span v-for="(recipient, index) in cc" :key="index">
                <p
                  class="error-text"
                  v-if="v$.ccItems.$each.$response.$errors[index].item.length"
                  aria-live="polite"
                >
                  {{ recipient }} is not a valid email
                </p>
              </span>
            </div>
            <div v-if="showBcc" class="form-input" id="bcc-input">
              <label for="bcc" class="bold">Bcc</label>
              <multiselect
                v-model="bcc"
                :placeholder="''"
                :options="emailAddressOptions"
                :multiple="true"
                :taggable="true"
                @tag="addBcc"
              >
                <template #noOptions>Please input email.</template>
              </multiselect>
              <span v-for="(recipient, index) in bcc" :key="index">
                <p
                  class="error-text"
                  v-if="v$.bccItems.$each.$response.$errors[index].item.length"
                  aria-live="polite"
                >
                  {{ recipient }} is not a valid email
                </p>
              </span>
            </div>
          </div>

          <div class="modal-form-field">
            <label for="subject" class="bold">Subject</label>
            <p class="description-text">
              Close the modal to edit your subject.
            </p>
            <input
              id="subject"
              class="not-allowed input"
              :value="email.subject"
              readonly
              ref="subject"
            />
          </div>

          <div class="modal-form-field">
            <label for="draft-content" class="bold">Draft</label>
            <p class="description-text">
              Close the modal to edit your draft.
            </p>
            <email-content
              id="draft-content"
              :email="email"
              :hide-subject="true"
              class="not-allowed input draft-content"
            ></email-content>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <button
          class="button full primary fill"
          @click="confirmSendEmail"
          :disabled="!canSendEmail"
        >
          Send
          <i v-if="isEmailSending" class="fas fa-circle-notch fa-spin fa-fw"></i>
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { helpers, required, email } from "@vuelidate/validators";

import EmailContent from "shared/components/email/EmailContent.vue";
import Modal from "shared/components/Modal.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "ComposeEmailModal",

  components: {
    Multiselect,
    Modal,
    EmailContent,
  },
  setup () {
    return { v$: useVuelidate() };
  },
  props: {
    email: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    senderEmail: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      recipients: [],
      cc: [],
      bcc: [],
      showCc: false,
      showBcc: false,
      emailAddressOptions: [],
      isEmailSending: false,
    };
  },

  computed: {
    canSendEmail() {
      return this.recipients.length > 0 && !this.v$.$invalid;
    },

    recipientItems() {
      return this.recipients.map(r => ({item: r}));
    },

    ccItems() {
      return this.cc.map(cc => ({item: cc}));
    },

    bccItems() {
      return this.bcc.map(bcc => ({item: bcc}));
    },
  },

  methods: {
    cancel() {
      this.$emit("cancel");
      this.close();
    },

    close() {
      this.$emit("close");
    },

    confirmSendEmail() {
      this.$emit("send-email", {
        recipients: this.recipients,
        cc: this.cc,
        bcc: this.bcc,
      });
      this.isEmailSending = true;
      this.recipients = [];
      this.cc = [];
      this.bcc = [];
    },

    toggleCc() {
      this.showCc = !this.showCc;
      if (!this.showCc) {
        this.cc = [];
      }
    },

    toggleBcc() {
      this.showBcc = !this.showBcc;
      if (!this.showBcc) {
        this.bcc = [];
      }
    },

    addRecipient(newAddress) {
      this.recipients.push(newAddress);
    },

    addCc(newAddress) {
      this.cc.push(newAddress);
    },

    addBcc(newAddress) {
      this.bcc.push(newAddress);
    },
  },

  validations () {
    return {
      recipients: {
        required,
      },
      recipientItems: {
        $each: helpers.forEach({item: {email}}),
      },
      ccItems: {
        $each: helpers.forEach({item: {email}}),
      },
      bccItems: {
        $each: helpers.forEach({item: {email}}),
      },
    };
  }

};
</script>
