<template>
  <div class="tab-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 50">
      <rect
        stroke-linejoin="round"
        stroke-linecap="round"
        class="with-stroke"
        stroke="#596573"
        stroke-width="4"
        fill="none"
        height="40"
        width="14"
        x="11"
        y="5"
      />
      <rect
        class="with-solid-fill square-1"
        fill="#596573"
        height="7.5"
        width="5"
        x="15.5"
        rx="1"
        ry="1"
        y="9"
      />
      <rect
        class="with-solid-fill square-2"
        fill="#596573"
        height="15"
        width="5"
        x="15.5"
        y="26"
        rx="1"
        ry="1"
      />
      <rect
        transform="translate(64 50) rotate(180)"
        stroke-linejoin="round"
        stroke-linecap="round"
        class="with-stroke"
        stroke="#596573"
        stroke-width="4"
        height="40"
        fill="none"
        width="14"
        x="25"
        y="5"
      />
      <rect
        class="with-solid-fill square-3"
        fill="#596573"
        height="15"
        width="5"
        x="29.5"
        rx="1"
        ry="1"
        y="9"
      />
      <rect
        class="with-solid-fill square-4"
        fill="#596573"
        height="7.5"
        width="5"
        x="29.5"
        y="33.5"
        rx="1"
        ry="1"
      />
      <line
        stroke-linejoin="round"
        stroke-linecap="round"
        class="with-stroke"
        stroke-width="4"
        stroke="#596573"
        fill="none"
        y1="45"
        x2="45"
        y2="45"
        x1="5"
      />
    </svg>
  </div>
</template>
