<template>
  <div class="template-members-container">
    <div class="details-container">
      <header>
        <div class="header-container">
          <h2 id="members-heading" class="text-med new-serif bold">Members</h2>
          <onboarding-button
            @click.native="showOnboardingModal = true"
            ref="onboardingButton"
          ></onboarding-button>
        </div>
        <div class="button-container">
          <button
            v-if="canvasAssociations.length > 0"
            class="button-secondary"
            @click="importStudentsFromCanvas"
          >
            Import Students from Canvas
          </button>
          <button
            class="button-secondary"
            @click="showBulkUserUploadModal = true"
            aria-haspopup="dialog"
            ref="importButton"
          >
            Import from CSV
          </button>
          <button
            ref="addMemberButton"
            class="button"
            @click="showAddModal = true"
            aria-haspopup="dialog"
          >
            Add Member
          </button>
        </div>
      </header>
      <div class="table-header">
        <span class="row-item">Name</span>
        <span class="row-item">Email</span>
        <span class="row-item">Permission Level</span>
        <span class="row-item">Actions</span>
      </div>
      <ul class="members no-list-styling" aria-labelledby="members-heading">
        <member-item
          class="table-row"
          v-for="(member, i) in members"
          :key="i"
          :member="member"
          :can-edit="$can(removePermissionName(member), template)"
          :can-delete="$can(removePermissionName(member), template)"
          :noActions="false"
          @edit="editMember(member)"
          @delete="deleteMember(member)"
        />
      </ul>
    </div>
    <div
      class="details-container members-of-collection"
      v-for="collection in viewableCollections"
      :key="collection.id"
    >
      <header>
        <h2 class="text-med new-serif bold" :id="'members-of-' + collection.name">
          Members of <span class="template-collections-title">"{{ collection.name }}"</span>
        </h2>
        <Can I="change" :this="collection">
          <router-link
            class="button"
            :to="{ name: 'CollectionMembers', params: { collectionId: collection.id } }"
          >
            Manage Collection Members
          </router-link>
        </Can>
      </header>
      <div class="table-header">
        <span class="row-item">Name</span>
        <span class="row-item">Email</span>
        <span class="row-item">Permission Level</span>
      </div>
      <ul class="members no-list-styling" :aria-labelledby="'members-of-' + collection.name">
        <member-item
          class="table-row"
          v-for="(member, i) in collectionMembers[collection.id]"
          :key="i"
          :member="member"
          :can-delete="false"
          :noActions="true"
          @delete="deleteMember(member)"
        />
      </ul>
    </div>
    <member-modal-add
      :visible="showAddModal"
      :member-service="memberService"
      :service-item-id="serviceItemId"
      :allowed-roles="allowedMemberTypesForAddition"
      @close="
        showAddModal = false;
        $refs.addMemberButton.focus();
      "
      @added-member="handleAddedMember"
    ></member-modal-add>
    <member-modal-edit
      v-if="memberToEdit !== null"
      :allowed-roles="allowedMemberTypesForAddition"
      :member="memberToEdit"
      :error-message="editErrorMessage"
      @close="memberToEdit = null"
      @save="handleEditMember(memberToEdit, $event)"
    ></member-modal-edit>
    <member-modal-bulk-upload
      :visible="showBulkUserUploadModal"
      :member-service="memberService"
      :service-item-id="template.id"
      @close="
        showBulkUserUploadModal = false;
        $refs.importButton.focus();
      "
      @uploaded="getMembers"
    ></member-modal-bulk-upload>
    <confirmation-dialog
      v-if="memberToDelete !== null"
      accept-label="Remove"
      :message="`Are you sure you want to remove member “${memberToDelete.email}”`"
      :error-message="deleteErrorMessage"
      @cancel="memberToDelete = null"
      @accept="handleDeleteMember(memberToDelete)"
    />
    <template-members-modal
      v-if="showOnboardingModal"
      :template="template"
      @close="closeOnboarding"
    ></template-members-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import TemplateService from "services/template";
import CollectionService from "services/collections";

import ConfirmationDialog from "shared/components/dashboard/ConfirmationDialog.vue";
import MemberModalAdd from "shared/components/members/MemberModalAdd.vue";
import MemberModalBulkUpload from "shared/components/members/MemberModalBulkUpload.vue";
import MemberModalEdit from "shared/components/members/MemberModalEdit.vue";
import MemberItem from "shared/components/members/MemberItem.vue";
import OnboardingButton from "../components/OnboardingButton.vue";
import TemplateMembersModal from "shared/components/template/TemplateMembersModal.vue";

export default {
  name: "TemplateDetailMembers",
  components: {
    MemberModalAdd,
    MemberModalBulkUpload,
    ConfirmationDialog,
    MemberModalEdit,
    MemberItem,
    OnboardingButton,
    TemplateMembersModal,
  },

  props: {
    template: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      templateMembers: [],
      showAddModal: false,
      collectionMembers: {},
      memberToDelete: null,
      deleteErrorMessage: null,
      memberToEdit: null,
      editErrorMessage: null,
      showBulkUserUploadModal: false,
      canvasAssociations: [],
      showOnboardingModal: false,
    };
  },

  created() {
    this.getCanvasInfo();
    this.getMembers();
    this.refreshCollectionMembers();
  },

  computed: {
    ...mapState(["user"]),
    ...mapGetters("collection", { getCollectionByTemplateId: "getCollectionByTemplateId" }),
    members() {
      return this.templateMembers;
    },
    memberService() {
      return TemplateService;
    },
    serviceItemId() {
      return this.template.id;
    },
    viewableCollections() {
      const template = this.template;
      const collections = this.getCollectionByTemplateId(template.id);
      return collections.filter((c) => this.$can("view", c));
    },
    allowedMemberTypesForAddition() {
      const membershipPermRE = /^groups\.add_documenttemplate_(\w+)$/;
      const template = this.template;
      const perms = template.perms;
      const permMatches = perms.map((p) => p.match(membershipPermRE)).filter((m) => m !== null);
      return permMatches.map((m) => m[1]);
    },
  },

  watch: {
    template() {
      this.getMembers();
    },
    viewableCollections() {
      this.refreshCollectionMembers();
    },
  },

  methods: {
    ...mapActions("template", ["getTemplates"]),
    getMembers() {
      return TemplateService.listMembers(this.template.id).then((response) => {
        this.templateMembers = response.data;
      });
    },
    refreshCollectionMembers() {
      this.collectionMembers = {};
      this.viewableCollections.forEach((collection) => {
        this.getCollectionMembers(collection);
      });
    },
    getCollectionMembers(collection) {
      this.collectionMembers[collection.id] = [];
      CollectionService.listMembers(collection.id).then((response) => {
        if (response.status == 200) {
          this.collectionMembers[collection.id] = response.data;
          this.collectionMembers = Object.assign({}, this.collectionMembers);
        }
      });
    },
    deleteMember(member) {
      this.memberToDelete = member;
    },
    editMember(member) {
      this.memberToEdit = member;
    },

    handleDeleteMember(member) {
      this.deleteErrorMessage = null;
      TemplateService.destroyMember(this.template.id, member.id)
        .then(() => {
          this.deleteErrorMessage = null;
          this.memberToDelete = null;
          this.templateMembers = this.templateMembers.filter((m) => m.id !== member.id);
        })
        .catch((error) => {
          this.deleteErrorMessage = error.data[0];
        });
    },
    handleEditMember(member, details) {
      this.editErrorMessage = null;
      return TemplateService.updateMember(this.template.id, member.id, details)
        .then(() => {
          this.editErrorMessage = null;
          if (member.email === this.user.email) {
            return this.getTemplates()
              .then(() => this.getMembers())
              .catch(() => {
                this.memberToEdit = null;
                this.$router.push({ name: "Template", params: { templateId: this.template.id } });
              });
          } else {
            return this.getMembers();
          }
        })
        .then(() => {
          this.memberToEdit = null;
        })
        .catch((error) => {
          this.editErrorMessage = error.data[0];
        });
    },
    handleAddedMember(member) {
      const memberExistsAlready = this.templateMembers.find((m) => m.id === member.id);
      if (!memberExistsAlready) {
        this.templateMembers.push(member);
      }
    },

    getCanvasInfo() {
      return TemplateService.listCanvasAssociations(this.template.id).then((response) => {
        if (response.status === 200) {
          this.canvasAssociations = response.data;
        }
      });
    },
    importStudentsFromCanvas() {
      const promises = this.canvasAssociations.map((association) => {
        return TemplateService.importStudentsFromCanvasAssociation(
          this.template.id,
          association.id
        );
      });

      Promise.all(promises).then(() => {
        this.getMembers();
      });
    },

    removePermissionName(member) {
      let roleNameComponents = member.role.split(" ");
      let roleName = roleNameComponents[roleNameComponents.length - 1].toLowerCase();
      return `remove_${roleName}_of`;
    },

    closeOnboarding() {
      this.showOnboardingModal = false;
      this.$refs.onboardingButton.$el.focus();
    },
  },
};
</script>
