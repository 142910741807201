<template>
  <div class="tab-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 50">
      <rect
        class="with-stroke rectangle"
        stroke-miterlimit="10"
        stroke-width="4"
        stroke="#596573"
        fill="none"
        height="30"
        width="30"
        x="15"
        rx="4"
        ry="4"
        y="5"
      />
      <g>
        <rect
          class="with-stroke with-fill"
          stroke-miterlimit="10"
          stroke-width="4"
          stroke="#596573"
          height="30"
          width="30"
          y="15"
          rx="4"
          ry="4"
          x="5"
        />
        <line
          stroke-linejoin="round"
          stroke-linecap="round"
          class="with-stroke"
          stroke-width="4"
          stroke="#596573"
          fill="none"
          y1="37.5"
          y2="22.5"
          x1="20"
          x2="20"
        />
        <line
          stroke-linejoin="round"
          stroke-linecap="round"
          class="with-stroke"
          stroke-width="4"
          stroke="#596573"
          fill="none"
          x1="12.5"
          x2="27.5"
          y1="30"
          y2="30"
        />
      </g>
    </svg>
  </div>
</template>
