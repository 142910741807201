<template>
  <div>
    <div class="contributor-info">
      <h4>David Corneail</h4>
      <p>Information Architect</p>
    </div>
    <div class="contributor-info">
      <h4>Holly Derry</h4>
      <p>Associate Director, Behavioral Science</p>
    </div>
    <div class="contributor-info">
      <h4>Nathan Haynes-Magyar</h4>
      <p>Senior Software Developer</p>
    </div>
    <div class="contributor-info">
      <h4>Sundeep Nagumalli</h4>
      <p>Systems Administrator</p>
    </div>
    <div class="contributor-info">
      <h4>David Nesbitt</h4>
      <p>Software Portfolio Manager</p>
    </div>
    <div class="contributor-info">
      <h4>Dennis O’Reilly</h4>
      <p>Director, Application Architecture & Software Development</p>
    </div>
    <div class="contributor-info">
      <h4>Becky Peng</h4>
      <p>User Experience Design Fellow</p>
    </div>
    <div class="contributor-info">
      <h4>Ollie Saunders</h4>
      <p>Software Development & DevOps Manager</p>
    </div>
    <div class="contributor-info">
      <h4>Jenny Ye</h4>
      <p>Software Developer</p>
    </div>
    <div class="contributor-info">
      <h4>Xucong Zhan</h4>
      <p>Software Developer</p>
    </div>
  </div>
</template>