<template>
  <svg class="doodle draft" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <circle class="cls-5" cx="50" cy="50" r="60"/>
    <path class="cls-2 tag-2" d="M61.84,39.88H21.31v33.75h40.53l14.66-12.21c2.92-2.43,2.92-6.91,0-9.33l-14.66-12.21Z"/>
    <g class="tag-1">
      <path class="cls-1" d="M61.84,26.38H21.31v33.75h40.53l14.66-12.21c2.92-2.43,2.92-6.91,0-9.33l-14.66-12.21Z"/>
      <circle class="cls-3" cx="64.65" cy="43.25" r="1.69"/>
      <rect class="cls-4 line-1" x="31.44" y="38.19" width="20.25" height=".25"/>
      <rect class="cls-4 line-2" x="31.44" y="48.31" width="10.125" height=".25"/>
    </g>
  </svg>
</template>

<style scoped>
  .cls-1 {
    fill: #80cdd6;
    stroke: #80cdd6;
  }

  .cls-1, .cls-2 {
    stroke-width: 5.4px;
  }

  .cls-1, .cls-2, .cls-3, .cls-4 {
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .cls-2, .cls-3 {
    fill: #333;
  }

  .cls-2, .cls-3, .cls-4 {
    stroke: #333;
  }

  .cls-3 {
    stroke-width: 2.7px;
  }

  .cls-4 {
    fill: none;
    stroke-width: 5px;
  }

  .cls-5 {
    fill: #fff;
    stroke-width: 0px;
  }
</style>