<template>
  <div class="container viewing-modes">
    <div class="wide-container">
      <div class="viewing-modes-container">
        <div class="mode-and-tooltip">
          <div
            class="viewing-mode"
            role="radio"
            :aria-checked="learnerModeSelected ? 'true' : 'false'"
            aria-labelledby="viewing-mode-radio1-label"
            data-value="Writer"
            :class="{ active: learnerModeSelected }"
            @click="selectLearner"
            tabindex="0"
            aria-label="Writer Mode"
          >
            <div class="viewing-mode-header">
              <h3 id="viewing-mode-radio1-label">Writer</h3>
              <div
                v-if="learnerModeSelected"
                aria-label="Checkmark icon"
                class="selected-icon button-icon"
              ></div>
            </div>
            <p>I'm using Lettersmith to improve my writing.</p>
          </div>
          <div class="help-text">
            <p class="description-text">
              Note: Please read more about the <b>Writer</b> mode if you need assistance:
            </p>
            <button
              class="button-icon tooltip-icon white-hover"
              @click.stop="showLearnerModal = true"
              ref="learnerModalTrigger"
              aria-label="Open writer tooltip"
            ></button>
          </div>
        </div>
        <div class="mode-and-tooltip">
          <div
            class="viewing-mode"
            role="radio"
            :aria-checked="adminModeSelected ? 'true' : 'false'"
            aria-labelledby="viewing-mode-radio2-label"
            data-value="Admin"
            :class="{ active: adminModeSelected }"
            @click="selectAdmin"
            tabindex="0"
            aria-label="Admin Mode"
          >
            <div class="viewing-mode-header">
              <h3 id="viewing-mode-radio2-label">Admin</h3>
              <div
                v-if="adminModeSelected"
                aria-label="Checkmark icon"
                class="selected-icon button-icon"
              ></div>
            </div>
            <p>I'm using Lettersmith to help learners improve their writing.</p>
          </div>
          <div class="help-text">
            <p class="description-text">
              Note: Please read more about the <b>Admin</b> mode if you need assistance:
            </p>
            <button
              class="button-icon tooltip-icon white-hover"
              @click.stop="showAdminModal = true"
              ref="adminModalTrigger"
              aria-label="Open admin tooltip"
            ></button>
          </div>
        </div>
      </div>
      <button :disabled="disabled" class="button" @click="updateViewingMode">
        Update Viewing Mode
      </button>
    </div>
    <writer-modal-details
      v-if="showLearnerModal"
      @close="hideLearnerModalAndRefocus"
    ></writer-modal-details>
    <admin-modal-details
      v-if="showAdminModal"
      @close="hideAdminModalAndRefocus"
    ></admin-modal-details>
  </div>
</template>
  
  <script>
import WriterModalDetails from "./WriterModalDetails.vue";
import AdminModalDetails from "./AdminModalDetails.vue";
import UserService from "services/user";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    WriterModalDetails,
    AdminModalDetails,
  },
  data() {
    return {
      learnerModeSelected: false,
      adminModeSelected: false,
      disabled: true,
      showViewingModesModal: false,
      showLearnerModal: false,
      showAdminModal: false,
      errorMessage: "",
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapActions(["updateUserViewingMode", "showSnackbar"]),
    selectLearner() {
      this.learnerModeSelected = true;
      this.adminModeSelected = false;
      this.disabled = false;
    },
    selectAdmin() {
      this.learnerModeSelected = false;
      this.adminModeSelected = true;
      this.disabled = false;
    },
    hideLearnerModalAndRefocus() {
      this.showLearnerModal = false;
      this.$refs.learnerModalTrigger.focus();
    },
    hideAdminModalAndRefocus() {
      this.showAdminModal = false;
      this.$refs.adminModalTrigger.focus();
    },
    updateViewingMode() {
      UserService.updateViewingMode({
        is_writer: this.learnerModeSelected,
      })
        .then((response) => {
          if (response.data.success) {
            this.updateUserViewingMode(response.data.data.is_writer);
            this.showSnackbar("Successfully updated your viewing mode!");
            this.disabled = true;
          } else {
            this.errorMessage = response.data.error;
          }
        })
        .catch((err) => {
          this.errorMessage = err.data.error;
        });
    },
  },
  created() {
    if (this.user.is_writer) {
      this.learnerModeSelected = true;
      this.adminModeSelected = false;
    } else {
      this.learnerModeSelected = false;
      this.adminModeSelected = true;
    }
  },
};
</script>
