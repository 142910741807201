<template>
  <div class="details-container">
    <header>
      <div class="header-container">
        <h2 class="text-med new-serif bold">Drafts</h2>
        <onboarding-button
          @click.native="showOnboardingModal = true"
          ref="onboardingButton"
        ></onboarding-button>
      </div>
      <button class="button" :disabled="!canCreateDraft" @click="handleCompose">
        Compose New Draft
      </button>
      <div class="snackbar disabled-compose-button">
        This template will become available once tags are added!
      </div>
    </header>
    <drafts-browser :drafts="myDrafts" @delete="handleDelete" @edit="handleEdit" />
    <template-drafts-modal
      v-if="showOnboardingModal"
      :template="template"
      @close="closeOnboarding"
    ></template-drafts-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import DraftsService from "services/drafts";
import EmailService from "services/email";

import DraftsBrowser from "../components/DraftsBrowser.vue";
import OnboardingButton from "../components/OnboardingButton.vue";
import TemplateDraftsModal from "shared/components/template/TemplateDraftsModal.vue";

export default {
  name: "TemplateDetailDrafts",

  components: {
    DraftsBrowser,
    OnboardingButton,
    TemplateDraftsModal,
  },

  props: {
    template: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      drafts: [],
      loading: false,
      showOnboardingModal: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapState("template", {
      allChecklistItems: "allChecklistItems",
    }),

    myDrafts() {
      let drafts = this.drafts || [];
      let user = this.user;

      return drafts.filter((draft) => draft.user_id == user.id);
    },
    checklistItems() {
      let template = this.template;

      return this.allChecklistItems.filter((cli) => cli.template_id == template.id);
    },
    canCreateDraft() {
      return this.checklistItems.length !== 0;
    },
  },
  mounted() {
    this.getDrafts();
  },
  watch: {
    template() {
      this.getDrafts();
    },
  },
  methods: {
    ...mapActions(["createNewDraft"]),
    ...mapActions("drafting", ["setCurrentDraft"]),
    getDrafts() {
      this.loading = true;
      return DraftsService.list(this.template.id)
        .then((response) => {
          this.drafts = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCompose() {
      this.createNewDraft({ template: this.template }).then((draft) => {
        this.$router.push({
          name: "Draft",
          params: {
            slug: draft.slug,
          },
        });
      });
    },
    handleDelete(draft) {
      EmailService.destroy(draft.id).then(() => {
        this.drafts = this.drafts.filter((d) => d.id != draft.id);
      });
    },
    async handleEdit(email) {
      const { data: draft } = await DraftsService.load(email.slug);
      this.setCurrentDraft(draft);
      this.$router.push({
        name: "Draft",
        params: {
          slug: draft.slug,
        },
      });
    },
    closeOnboarding() {
      this.showOnboardingModal = false;
      this.$refs.onboardingButton.$el.focus();
    },
  },
};
</script>
