<template>
  <svg class="doodle example" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <circle class="cls-4" cx="50" cy="50" r="60"/>
    <rect class="cls-2 example-2" x="22.5" y="25" width="37.5" height="50" rx="5" ry="5"/>
    <rect class="cls-3 example-3" x="31.25" y="25" width="37.5" height="50" rx="5" ry="5"/>
    <g class="example-1">
      <rect class="cls-2" x="40" y="25" width="37.5" height="50" rx="5" ry="5"/>
      <polygon class="cls-1 bookmark" points="63.75 25 58.75 25 58.75 43.75 63.75 38.39 68.75 43.75 68.75 25 63.75 25"/>
    </g>
  </svg>
</template>

<style scoped>
  .cls-1 {
    stroke-miterlimit: 10;
  }

  .cls-1, .cls-2, .cls-3 {
    stroke-linecap: round;
    stroke-width: 5px;
  }

  .cls-1, .cls-3 {
    fill: #333;
    stroke: #333;
  }

  .cls-2 {
    fill: #92cb88;
    stroke: #92cb88;
  }

  .cls-2, .cls-3 {
    stroke-linejoin: round;
  }

  .cls-4 {
    fill: #fff;
    stroke-width: 0px;
  }
</style>