<template>
  <div class="template-section">
    <section class="your-templates">
      <div class="dashboard-header">
        <div class="flex">
          <img
            src="/static/images/landing-page/scalloped-shape-primary-blue.svg"
            alt=""
            class="dash-icon"
          />
          <h2 id="templates-heading" class="text-med new-serif bold">My Templates</h2>
          <!--
          <p class="text-small">
            Templates help provide support to writers in the drafting stage of the writing process.
          </p>
          -->
        </div>
        <div class="buttons">
          <button
            ref="joinTemplateButton"
            @click="showJoinTemplateModal = true"
            aria-haspopup="dialog"
            class="button-secondary"
          >
            Join Template
          </button>
          <button
            v-if="!user.is_writer"
            ref="createTemplateButton"
            @click="showCreateTemplateModal = true"
            aria-haspopup="dialog"
            class="button"
          >
            Create Template
          </button>
        </div>
      </div>
      <ul class="all-templates-list all-templates" v-if="myTemplates.length > 0" aria-labelledby="templates-heading">
        <template-card v-for="template in myTemplates" :key="template.id" :template="template" />
      </ul>
      <div class="empty-state" v-else>
        <img alt="" class="empty-image" src="/static/images/empty-states/empty-examples.png" />
        <p class="text-med new-serif bold">No Templates Found!</p>
        <p>If you are an <b>instructor,</b> create templates to help guide your writers as they create their draft.</p>
        <p>If you are a <b>student,</b> ask your instructor for an invite through a code, link, or email. Typically, you won’t be creating templates yourself as a student.</p>
      </div>
      <template-modal-create
        v-if="showCreateTemplateModal"
        @close="
          showCreateTemplateModal = false;
          $refs.createTemplateButton.focus();
        "
        @created-template="handleNewTemplateCompletion"
      ></template-modal-create>
      <template-modal-join
        v-if="showJoinTemplateModal"
        @close="
          showJoinTemplateModal = false;
          $refs.joinTemplateButton.focus();
        "
        @joined-template="handleJoinedTemplate"
      ></template-modal-join>
    </section>
    <section class="public-templates">
      <div class="dashboard-header">
        <div class="flex">
          <img
            src="/static/images/landing-page/starburst-shape-yellow.svg"
            alt=""
            class="dash-icon"
          />
          <h2 id="templates-heading" class="text-med new-serif bold">Public Templates</h2>
        </div>
        <!--
        <div class="buttons">
          <button
            ref="browseTemplatesButton"
            class="button-tertiary"
          >
            Browse Templates
          </button>
        </div>
      -->
      </div>
      <ul class="all-templates-list all-public-templates" v-if="publicTemplates.length > 0" aria-labelledby="templates-heading">
        <li class="template-card" v-for="template in publicTemplates" :key="template.id">
          <public-template-card :template="template"></public-template-card>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

import PublicTemplateCard from "shared/components/template/PublicTemplateCard.vue";
import TemplateCard from "shared/components/template/TemplateCard.vue";
import TemplateModalCreate from "shared/components/template/TemplateModalCreate.vue";
import TemplateModalJoin from "shared/components/template/TemplateModalJoin.vue";

export default {
  components: {
    PublicTemplateCard,
    TemplateCard,
    TemplateModalCreate,
    TemplateModalJoin,
  },
  data() {
    return {
      showCreateTemplateModal: false,
      showJoinTemplateModal: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapState("template", {
      templates: "allTemplates",
    }),
    ...mapGetters("template", {
      getPublicTemplates: "getPublicTemplates",  
      getMyTemplates: "getMyTemplates"
    }),
    publicTemplates() {
      return this.getPublicTemplates;
    },
    myTemplates() {
      return this.getMyTemplates;
    },
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    handleNewTemplateCompletion(template) {
      this.showCreateTemplateModal = false;
      this.showSnackbar(`Successfully created ${template.name}`);
      this.$router.push({ name: "Template", params: { templateId: template.id } });
    },
    handleJoinedTemplate(template) {
      this.showJoinTemplateModal = false;
      this.showSnackbar(`Successfully joined ${template.name}`);
      this.$router.push({ name: "Template", params: { templateId: template.id } });
    },
  },
};
</script>
