<template>
  <div class="draft-checklist checklist">
    <div class="draft-view__header">
      <div class="header-container">
        <h2 class="text-med new-serif bold">{{ checklistName }}</h2>
        <div class="tooltip-container">
          <button
            class="button-icon tooltip-icon"
            @click="showChecklistTooltip = true"
            aria-label="Open Checklist Tooltip"
          >
          </button>
          <checklist-tooltip
            v-if="showChecklistTooltip"
            @close="showChecklistTooltip = false"
          ></checklist-tooltip>
        </div>
      </div>
      <span class="text-xsmall error-text bold">* Required</span>
    </div>
    <p class="text-xsmall instructions">
      <span class="bold">How to Tag:</span> Highlight your writing and then select the appropriate
      tag below to properly tag your draft.
    </p>
    <div class="checklist-panel" v-if="checklistGroups">
      <checklist-group
        v-for="group in checklistGroups"
        :tagged-checklist-item-ids="taggedChecklistItemIds"
        :key="group.title"
        :group="group"
        @select="select"
      >
      </checklist-group>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

import ChecklistGroup from "./ChecklistGroupSubmission.vue";
import ChecklistTooltip from "./ChecklistTooltip.vue";

export default defineComponent({
  name: "ChecklistContent",

  components: {
    ChecklistGroup,
    ChecklistTooltip,
  },

  props: {
    checklistGroups: {
      type: Array,
      required: true,
    },
    taggedChecklistItemIds: {
      type: Array,
      required: true,
    },
    checklistName: {
      type: String,
      default: "Checklist",
    },
  },

  emits: ["checklist-item-selected", "selected-checklist-item-restored"],

  setup(props, context) {
    const showChecklistTooltip = ref(false);
    const selectedChecklistItem = ref(null);

    const select = (item) => {
      selectedChecklistItem.value = item;
      context.emit("checklist-item-selected", [item.id]);
    };

    return {
      showChecklistTooltip,
      selectedChecklistItem,
      select,
    };
  },
});
</script>
