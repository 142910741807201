<template>
  <div class="details-container example-details">
    <header>
      <div class="header-container">
        <h2 class="text-med new-serif bold">Examples</h2>
        <onboarding-button
          @click.native="showOnboardingModal = true"
          ref="onboardingButton"
        ></onboarding-button>
      </div>
      <div class="button-container" v-if="examples.length > 0">
        <button aria-label="Browse Left" class="left button" :disabled="examples.length == 1" @click="rotate(-1)">
          <img alt class="icon arrow" src="/static/images/icons/arrow-white.png" />
        </button>
        <span class="example-numbers">{{ currentIndex + 1 }}/{{ examples.length }}</span>
        <button aria-label="Browse Right" class="right button" :disabled="examples.length == 1" @click="rotate(1)">
          <img alt class="icon arrow" src="/static/images/icons/arrow-white.png" />
        </button>
      </div>
    </header>
    <example-detail :example="currentExample" v-if="examples.length > 0" />
    <div class="empty-state" v-else>
      <img alt="" class="empty-image" src="/static/images/empty-states/empty-examples.png" />
      <p class="text-med serif">There are no examples to share!</p>
      <p>
        The owners of this template have yet to approve any examples. Compose a draft and be the
        first to submit one!
      </p>
    </div>
    <template-examples-modal
      v-if="showOnboardingModal"
      :template="template"
      @close="closeOnboarding"
    ></template-examples-modal>
  </div>
</template>

<script>
import ExampleDetail from "./ExampleDetail.vue";
import OnboardingButton from "../components/OnboardingButton.vue";
import TemplateExamplesModal from "shared/components/template/TemplateExamplesModal.vue";

export default {
  components: {
    ExampleDetail,
    OnboardingButton,
    TemplateExamplesModal,
  },
  props: {
    examples: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
      showOnboardingModal: false,
    };
  },
  computed: {
    currentExample() {
      let examples = this.examples;
      let currentIndex = this.currentIndex;

      if (examples.length > 0) {
        return examples[currentIndex];
      }

      return null;
    },
  },
  watch: {
    examples(examples) {
      this.currentIndex = Math.max(0, Math.min(examples.length - 1, this.currentIndex));
    },
  },
  methods: {
    rotate(step) {
      let currentIndex = this.currentIndex + step + this.examples.length;
      this.currentIndex = currentIndex % this.examples.length;
    },
    closeOnboarding() {
      this.showOnboardingModal = false;
      this.$refs.onboardingButton.$el.focus();
    },
  },
};
</script>
