<template>
  <div class="container interior flex column">
    <et-header></et-header>

    <main id="main" class="flex-fill draft-container">
      <div class="left-panel">
        <checklist-content
          :checklist-name="checklistName"
          :checklist-groups="checklistGroups"
          :checklist-items="checklistItems"
          :tagged-checklist-item-ids="taggedChecklistItemIds"
          @checklist-item-selected="setSelectedItemIds($event)"
          @selected-checklist-item-restored="setSelectedItemIds([])"
        ></checklist-content>
      </div>
      <div class="draft-view-container">
        <editor-view-header>
          <template #editor-view-header-title>
            <slot name="title">My Draft</slot>
          </template>
          <template #editor-view-header-middle>
            <span class="words">
              {{ draftWordCount }} Word{{ draftWordCount == 1  ? "" : "s" }}
            </span>
            <tag-switch
              :checked="isDisplayingAllTags"
              @toggle="setIsDisplayingAllTags"
            />
          </template>
        </editor-view-header>
        <div class="draft-view draft-content">
          <draft-content
            :draft="submission"
            :displayed-tags="draftTagsToBeDisplayed"
            @focus="$emit('focus')"
          ></draft-content>

          <slot name="footer-extra"></slot>
        </div>

        <div class="draft-view-footer">
          <div class="last-saved">Submitted: {{ submissionCreatedAt }}</div>

          <div class="buttons flex">
            <router-link
              class="button-secondary narrow"
              :to="{ name: 'TemplateSubmissions', params: { templateId: submission.template_id } }"
            >
              Return to Template
            </router-link>
          </div>
        </div>
      </div>
      <div class="right-panel"></div>
    </main>
  </div>
</template>

<script>
import { computed } from "vue";

import EditorViewHeader from "modules/drafting/components/EditorViewHeader.vue";
import DraftContent from "shared/components/draft/DraftContent.vue";
import ChecklistContent from "shared/components/checklist/ChecklistContent.vue";
import TagSwitch from "shared/components/TagSwitch.vue";

import { useSubmission } from "../composables/useSubmission";
import { useSubmissionUI } from "../composables/useSubmissionUI";
import { useStore } from "vuex";

export default {
  components: {
    DraftContent,
    EditorViewHeader,
    ChecklistContent,
    TagSwitch,
  },

  props: {
    slug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();

    const { submission } = useSubmission(props.slug);

    // ChecklistContent
    const checklistItems = computed(() => store.state.template.checklistItems);
    const checklistGroups = computed(() => store.state.template.checklistGroups);

    const taggedChecklistItemIds = computed(() =>
      submission.value.tags.map((tag) => tag.template_checklist_item_id)
    );
    const checklistName = computed(
      () => store.state.template.currentTemplate?.features?.checklist_name ?? "Checklist"
    );

    // UI
    const {
      draftWordCount,
      submissionCreatedAt,
      isDisplayingAllTags,
      setIsDisplayingAllTags,
      setSelectedItemIds,
      draftTagsToBeDisplayed,
    } = useSubmissionUI(submission.value);

    return {
      submission,
      checklistItems,
      checklistGroups,
      taggedChecklistItemIds,
      checklistName,
      draftWordCount,
      submissionCreatedAt,
      isDisplayingAllTags,
      setIsDisplayingAllTags,
      setSelectedItemIds,
      draftTagsToBeDisplayed,
    };
  },
};
</script>
