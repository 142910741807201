<template>
  <div v-if="collection && templates" class="collection-detail">
    <header class="collection-detail-header">
      <div class="collection-name">
        <h1 class="title text-med new-serif bold">
          {{ collection.name }}
        </h1>
        <role-tag v-for="role in collection.roles" :key="role" :role-name="role" />
        <Can I="change" :this="collection">
          <button
            ref="editButton"
            @click="showEditModal = true"
            class="button-link"
            aria-haspopup="true"
          >
            Edit
          </button>
        </Can>
        <Can I="delete" :this="collection">
          <button
            ref="deleteButton"
            class="button-link delete"
            @click="deleteThisCollection"
            aria-haspopup="true"
          >
            Delete Collection
          </button>
        </Can>
      </div>
      <p>{{ templates.length }} Template{{ templates.length !== 1 ? "s" : "" }}</p>
      <div class="detail-footer">
        <div class="collection-code text-small">Collection Code: {{ collection.code }}</div>
        <Can not I="delete" :this="collection">
          <button
            ref="leaveButton"
            class="button-link button-leave"
            @click="showLeaveConfirmation = true"
            aria-haspopup="true"
          >
            Leave Collection
          </button>
        </Can>
      </div>
    </header>

    <nav aria-label="Collection tabs">
      <ul class="collection-tabs no-list-styling">
        <tab-link :to="{ name: 'CollectionTemplates' }">
          <templates-icon class="template-tab tab-hovers"></templates-icon>
          Templates
        </tab-link>
        <Can I="change" :this="collection">
          <tab-link :to="{ name: 'CollectionMembers' }">
            <members-icon class="members-tab tab-hovers"></members-icon>
            Members
          </tab-link>
          <tab-link :to="{ name: 'CollectionSettings' }">
            <settings-icon class="settings-tab tab-hovers"></settings-icon>
            Settings </tab-link>
        </Can>
      </ul>
    </nav>
    <router-view :collection="collection" :templates="templates" />
    <edit-collection-modal
      v-if="showEditModal"
      :collection="collection"
      @close="
        showEditModal = false;
        $refs.editButton.focus();
      "
      @updated-collection="showEditModal = false"
    />
    <confirmation-dialog
      v-if="showDeleteConfirmation"
      :danger-mode="true"
      message="Are you sure you want to delete this collection?"
      accept-label="Delete"
      @cancel="
        showDeleteConfirmation = false;
        $refs.deleteButton.focus();
      "
      @accept="handleConfirmDelete"
    />
    <confirmation-dialog
      v-if="showLeaveConfirmation"
      :danger-mode="true"
      message="Are you sure you want to leave this collection?"
      accept-label="Leave"
      @cancel="
        showLeaveConfirmation = false;
        $refs.leaveButton.focus();
      "
      @accept="handleConfirmLeave"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ConfirmationDialog from "shared/components/dashboard/ConfirmationDialog.vue";
import RoleTag from "shared/components/dashboard/RoleTag.vue";
import TabLink from "shared/components/TabLink.vue";
import MembersIcon from "shared/components/icons/MembersIcon.vue";
import SettingsIcon from "shared/components/icons/SettingsIcon.vue";
import TemplatesIcon from "shared/components/icons/TemplatesIcon.vue";

import EditCollectionModal from "../components/EditCollectionModal.vue";

export default {
  components: {
    TabLink,
    EditCollectionModal,
    ConfirmationDialog,
    RoleTag,
    MembersIcon,
    SettingsIcon,
    TemplatesIcon,
  },
  props: {
    collectionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showEditModal: false,
      showDeleteConfirmation: false,
      showLeaveConfirmation: false,
    };
  },
  computed: {
    ...mapGetters("template", { getTemplatesByCollectionId: "getTemplatesByCollectionId" }),
    ...mapGetters("collection", { getCollectionById: "getCollectionById" }),

    collection() {
      return this.getCollectionById(this.collectionId);
    },

    templates() {
      return this.getTemplatesByCollectionId(this.collectionId);
    },
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    ...mapActions("collection", {
        removeCollection: "removeCollection",
        leaveCollection: "leaveCollection",
    }),

    deleteThisCollection() {
      this.showDeleteConfirmation = true;
    },

    handleConfirmDelete() {
      let collection = this.collection;
      this.removeCollection({ collection }).then(() => {
        this.showDeleteConfirmation = false;
        this.showSnackbar(`Successfully deleted ${collection.name}`);
        this.$router.push({ name: "Dashboard" });
      });
    },
    handleConfirmLeave() {
      const collection = this.collection;
      this.leaveCollection({ collection }).then(() => {
        this.showLeaveConfirmation = false;
        this.showSnackbar(`Successfully left ${collection.name}`);
        this.$router.push({ name: "Dashboard" });
      });
    },
  },
};
</script>
