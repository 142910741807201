<template>
  <div id="description-panel" class="draft-checklist checklist">
    <div class="draft-view__header">
      <div class="header-container">
        <h2 class="text-med new-serif bold">Description</h2>
      </div>
    </div>
    <div class="panel description-content" v-html-safe="template.description"></div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex';

export default defineComponent({
  name: "DescriptionView",
  setup() {
    const store = useStore();
    const template = computed(() => store.state.template.currentTemplate);

    return {
      template,
    };
  },
})
</script>
