<template>
  <div v-if="template" class="template-detail">
    <header class="template-detail-header">
      <div v-if="collections.length > 0" class="template-collections">
        <img class="folder-icon" src="/static/images/icons/folder-icon.svg" alt="" /> Collections:
        <template>
          <router-link
            v-for="(collection, index) in collections"
            :key="collection.id"
            :to="{ name: 'Collection', params: { collectionId: collection.id } }"
          >
            {{ collection.name }}<span v-if="index !== collections.length - 1">,</span>
          </router-link>
        </template>
      </div>
      <em class="no-collections" v-else>No Collections</em>
      <div class="template-name">
        <h1 class="title text-med new-serif bold">
          {{ template.name }}
        </h1>
        <div class="animation-container">
          <onboarding-button
            @click.native="showOnboardingModal = true, showTooltipAnimation = false"
            ref="onboardingButton"
          ></onboarding-button>
          <div v-if="showTooltipAnimation" class="tooltip-animation">
            <tooltip-animation json-path="/lottie/tooltip-animation.json" />
          </div>
        </div>
        <document-type-tag :document-type="template.document_type" />
        <role-tag v-for="roleName in roleNames" :key="roleName" :role-name="roleName" />
        <Can I="change" :this="template">
          <button
            ref="editButton"
            class="button-link"
            @click="showEditModal = true"
            aria-haspopup="dialog"
          >
            Edit
          </button>
        </Can>
        <Can I="delete" :this="template">
          <button
            ref="deleteButton"
            class="button-link delete"
            @click="deleteThisTemplate"
            aria-haspopup="dialog"
          >
            Delete Template
          </button>
        </Can>
        <Can I="change" :this="template">
          <button
            ref="linkModal"
            class="button-secondary"
            @click="showLinkModal = true"
            aria-haspopup="dialog"
          >
            Share Template Link
          </button>
        </Can>
      </div>
      <div class="detail-footer">
        <div class="template-code">Template Code: {{ template.code }}</div>
        <Can not I="delete" :this="template">
          <button
            ref="leaveButton"
            class="button-link button-leave"
            @click="showLeaveConfirmation = true"
            :disabled="!canLeaveTemplate"
            aria-haspopup="dialog"
          >
            Leave Template
          </button>
        </Can>
      </div>
    </header>

    <nav aria-label="Template tabs">
      <ul class="template-tabs no-list-styling">
        <Can I="change" :this="template">
          <tab-link :to="{ name: 'TemplateChecklist' }">
            <checklist-icon class="checklist-tab tab-hovers"></checklist-icon>
            {{ checklistName }}
          </tab-link>
          <tab-link :to="{ name: 'TemplateExamplesAdmin' }">
            <examples-icon class="example-tab tab-hovers"></examples-icon>
            Examples
          </tab-link>
        </Can>
        <tab-link :to="{ name: 'TemplateDrafts' }">
          <drafts-icon class="drafts-tab tab-hovers"></drafts-icon>
          My Drafts
        </tab-link>
        <tab-link
          v-if="template.features.can_submit_submission"
          :to="{ name: 'TemplateSubmissions' }"
        >
          <submissions-icon class="submissions-tab tab-hovers"></submissions-icon>
          Submissions
        </tab-link>
        <Can I="change" :this="template">
          <tab-link :to="{ name: 'TemplateMembers' }">
            <members-icon class="members-tab tab-hovers"></members-icon>
            Members
          </tab-link>
        </Can>
        <Can I="change" :this="template">
          <tab-link class="settings" :to="{ name: 'TemplateSettings' }">
            <settings-icon class="settings-tab tab-hovers"></settings-icon>
            Settings
          </tab-link>
        </Can>
        <Can not I="change" :this="template">
          <tab-link :to="{ name: 'TemplateExamples' }">
            <examples-icon class="example-tab tab-hovers"></examples-icon>
            Examples
          </tab-link>
        </Can>
      </ul>
    </nav>

    <router-view :template="template"></router-view>
    <template-modal-edit
      v-if="showEditModal"
      :template="template"
      @close="closeEditAndRefocus"
      @updated-template="showEditModal = false"
    ></template-modal-edit>
    <template-overview-modal
      v-if="showOnboardingModal"
      :template="template"
      @close="closeOnboarding"
    ></template-overview-modal>
    <confirmation-dialog
      v-if="showDeleteConfirmation"
      :danger-mode="true"
      accept-label="Delete"
      :super-confirm-mode="true"
      :super-confirm-text="template.name"
      @cancel="closeDeleteAndRefocus"
      @accept="onAcceptDelete"
    >
      <template #message>
        <h2 class="text-med">
          Are you sure you want to delete the template named “{{ template.name }}”?
        </h2>
        <div class="details">
          By deleting this template, you and all participants will loose all
          <ul>
            <li>Drafts</li>
            <li>Examples</li>
            <li>Tags</li>
          </ul>
        </div>
      </template>
    </confirmation-dialog>
    <confirmation-dialog
      v-if="showLeaveConfirmation"
      :danger-mode="true"
      message="Are you sure you want to leave this template?"
      accept-label="Leave"
      @cancel="
        showLeaveConfirmation = false;
        $refs.leaveButton.focus();
      "
      @accept="handleAcceptLeave"
    />
    <template-modal-link
      v-if="showLinkModal"
      :template="template"
      @close="
        showLinkModal = false;
        $refs.linkModal.focus();
      "
    ></template-modal-link>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import ConfirmationDialog from "shared/components/dashboard/ConfirmationDialog.vue";
import RoleTag from "shared/components/dashboard/RoleTag.vue";
import TabLink from "shared/components/TabLink.vue";

import DocumentTypeTag from "shared/components/template/TemplateDocumentTypeTag.vue";
import TemplateModalEdit from "shared/components/template/TemplateModalEdit.vue";
import TemplateModalLink from "shared/components/template/TemplateModalLink.vue"
import TemplateOverviewModal from "shared/components/template/TemplateOverviewModal.vue";
import OnboardingButton from "../components/OnboardingButton.vue";
import TooltipAnimation from "../components/TooltipAnimation.vue";
import ChecklistIcon from "shared/components/icons/ChecklistIcon.vue";
import DraftsIcon from "shared/components/icons/DraftsIcon.vue";
import ExamplesIcon from "shared/components/icons/ExamplesIcon.vue";
import MembersIcon from "shared/components/icons/MembersIcon.vue";
import SettingsIcon from "shared/components/icons/SettingsIcon.vue";
import SubmissionsIcon from "shared/components/icons/SubmissionsIcon.vue";

export default {
  name: "TemplateDetail",
  components: {
    DocumentTypeTag,
    TabLink,
    TemplateModalEdit,
    TemplateModalLink,
    TemplateOverviewModal,
    ConfirmationDialog,
    RoleTag,
    OnboardingButton,
    TooltipAnimation,
    ChecklistIcon,
    DraftsIcon,
    ExamplesIcon,
    MembersIcon,
    SettingsIcon,
    SubmissionsIcon,
  },
  props: {
    templateId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showEditModal: false,
      showDeleteConfirmation: false,
      showLeaveConfirmation: false,
      showLinkModal: false,
      errorText: null,
      showOnboardingModal: false,
      showTooltipAnimation: false,
    };
  },
  computed: {
    ...mapGetters("template", { getTemplateById: "getTemplateById" }),
    ...mapState("collection", { allCollections: "collections" }),

    template() {
      return this.getTemplateById(this.templateId);
    },

    collections() {
      const collections = this.allCollections;

      return collections.filter((c) => c.templates.includes(this.templateId));
    },

    roleNames() {
      return this.template.roles.map((r) => {
        const elements = r.split(" ");
        return elements[elements.length - 1];
      });
    },
    canLeaveTemplate() {
      return this.template.roles.length != 0;
    },
    checklistName() {
      return this.template?.features?.checklist_name ?? "Checklist";
    },
  },

  watch: {
    template: {
      handler: function (newValue) {
        if (newValue) {
          this.setCurrentTemplate(newValue);
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["showSnackbar"]),
    ...mapActions("template", {
      setCurrentTemplate: "setCurrentTemplate",
      removeTemplate: "removeTemplate",
      leaveTemplate: "leaveTemplate",
      getTemplates: "getTemplates"
    }),

    deleteThisTemplate() {
      this.showDeleteConfirmation = true;
    },
    async onAcceptDelete() {
      const template = this.template;
      const templateName = this.template.name;

      await this.removeTemplate({ template });
      this.showDeleteConfirmation = false;
      this.showSnackbar(`Successfully deleted ${templateName}`);
      this.$router.push({ name: "Dashboard" });
    },
    closeEditAndRefocus() {
      this.showEditModal = false;
      this.$refs.editButton.focus();
    },
    closeOnboarding() {
      this.showOnboardingModal = false;
      this.showTooltipAnimation = true;
      this.$refs.onboardingButton.$el.focus();
    },
    closeDeleteAndRefocus() {
      this.showDeleteConfirmation = false;
      this.$refs.deleteButton.focus();
    },
    async handleAcceptLeave() {
      const template = this.template;
      try {
        await this.leaveTemplate({ template });
        await this.getTemplates();
        this.showSnackbar(`Successfully left ${template.name}`);
        this.$router.push({ name: "Dashboard" });
      } catch {
        this.showSnackbar(`Not able to leave this template`);
      }
      this.showLeaveConfirmation = false;
    },
  },
};
</script>
