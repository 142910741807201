<template>
  <div v-if="draft" class="draft-editor flex column">
    <input
      class="draft-view-subject"
      :aria-label="subjectPlaceholder"
      :placeholder="subjectPlaceholder"
      v-model="localEmailSubject"
    />
    <div :id="draftElementId" class="quill-draft-editor">
      <div ref="quillEditor"></div>
      <a class="button jump-to-checklist" href="#checklist-panel-section">Checklist</a>
      <div id="quill-toolbar">
        <span class="ql-button-group">
          <button class="ql-bold" aria-label="Bold">Bold</button>
          <button class="ql-italic" aria-label="Italic">Italic</button>
          <button class="ql-underline" aria-label="Underline">Underline</button>
        </span>
        <span class="button-group-separator"></span>
        <span class="ql-button-group">
          <button class="ql-header" value="1" aria-label="Header level 1">
            Header 1
          </button>
          <button class="ql-header" value="2" aria-label="Header level 2">
            Header 2
          </button>
        </span>
        <span class="button-group-separator"></span>
        <span class="ql-button-group">
          <button class="ql-link" type="button" aria-label="Link"></button>
        </span>
        <span class="button-group-separator"></span>
        <span class="ql-button-group">
          <button class="ql-image" type="button" aria-label="Image"></button>
        </span>
        <span class="button-group-separator"></span>
        <span class="ql-button-group">
          <button class="ql-list" value="ordered" aria-label="Ordered list"></button>
          <button class="ql-list" value="bullet" aria-label="Unordered list"></button>
        </span>
        <span class="button-group-separator"></span>
        <span class="ql-button-group">
          <button class="ql-clean" type="button" aria-label="Clear styles"></button>
        </span>
      </div>
    </div>
    <a class="error-text" v-if="remove" @click="$emit('remove')" aria-live="polite"> Delete Email </a>
  </div>
</template>

<script>
import { QuillEditor } from "libs/editors/packages/quill.editor";

export default {
  name: "QuillEditor",

  components: {},

  props: {
    draft: {
      type: Object,
      required: true,
      default: null,
    },

    draftElementId: {
      type: String,
      default: "",
    },

    tags: {
      type: Array,
      required: true,
    },

    displayedTags: {
      type: Array,
      required: true,
    },

    toBeDeletedTagIds: {
      type: Array,
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
      default: "",
    },

    subjectPlaceholder: {
      type: String,
      required: false,
      default: "Subject",
    },

    label: {
      type: String,
      required: false,
      default: "",
    },

    remove: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localEmailSubject: "",
      editorOption: {
        modules: {
          toolbar: "#quill-toolbar",
          imageEdit: {
            modules: ["Resize", "DisplaySize", "Toolbar", "Delete"],
          },
          keyboard: {
            bindings: {
              tab: {
                key: 9,
                handler: function () {
                  return true;
                }
              },
            },
          },
        },
        placeholder: this.placeholder,
      },
    };
  },

  mounted() {
    this.initEditor();
    this.localEmailSubject = this.draft.subject;
  },

  watch: {
    tags(newValue) {
      this.editor.setTagList(newValue); //setTagList does not fire "tags-changed" event
    },
    displayedTags(newDisplayedTags, oldDisplayedTags) {
      if (newDisplayedTags.length > 0) {
        if (newDisplayedTags.length !== oldDisplayedTags.length) {
          this.editor.blur();
        }
        this.editor.highlightTags(newDisplayedTags, oldDisplayedTags);
      } else {
        this.editor.removeAllHighlighting();
      }
    },
    toBeDeletedTagIds(newValue) {
      newValue.forEach((tagId) => {
        this.editor.updateToBeDeletedTagsIds(tagId);
      });
    },
    localEmailSubject(newValue, oldValue) {
      if (oldValue) {
        this.$emit("text-change", { subject: newValue });
      }
    },
  },

  methods: {
    // editor init and setup
    initEditor() {
      const initConfig = {
        container: this.$refs.quillEditor,
        enabled: true,
        options: this.editorOption,
        html: this.draft.content,
        tags: this.tags,
        label: this.label,
      };
      this.editor = new QuillEditor(initConfig);

      this.editor.on("input", (e) => {
        this.$emit("input", e);
      });

      this.editor.on("selected", (e) => {
        const { range } = e;
        const selection = {
          start: range.index,
          end: range.index + range.length,
          content: this.editor.getTextByRange(range),
        };
        this.$emit("selected", selection);
        this.$emit("select", range);
      });

      this.editor.on("blur", () => {
        this.$emit("blur");
      });

      this.editor.on("focus", () => {
        this.$emit("focus");
      });

      this.editor.on("text-change", (e) => {
        // Only updates with source==="user" will trigger this event
        this.$emit("text-change", e);
      });

      this.editor.on("tags-changed", (tagList) => {
        this.$emit("tags-changed", tagList);
      });

      this.editor.on("tags-deleted", (toBeDeletedTagIds) => {
        this.$emit("tags-deleted", toBeDeletedTagIds);
      });

      if (this.displayedTags.length > 0) {
        this.editor.highlightTags(this.displayedTags, []);
      }

    },

    select(start, end) {
      this.editor.select(start, end);
    },

    getTextByRange(range) {
      return this.editor.getTextByRange(range);
    },

    replaceContent(range, replacedText) {
      this.editor.replaceText(range, replacedText);
    },

    blur() {
      this.editor.blur();
    },
  },
};
</script>
