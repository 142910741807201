import { createRouter, createWebHistory } from "vue-router";

import routes from "~/routes";
import store from "~/store";

import TrackingService from "services/tracking";

import { routes as AdminRoutes } from "modules/admin";
import { routes as AuthRoutes } from "modules/auth";
import { routes as DashboardRoutes } from "modules/dashboard";
import { routes as DraftingRoutes } from "modules/drafting";
import { routes as SubmissionRoutes } from "modules/submission";


const router = createRouter({
  history: createWebHistory(),
  hashbang: false,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const addRoute = (route) => router.addRoute(route);

AdminRoutes.forEach(addRoute);
AuthRoutes.forEach(addRoute);
DashboardRoutes.forEach(addRoute);
DraftingRoutes.forEach(addRoute);
SubmissionRoutes.forEach(addRoute);

// Save a tracking event when navigating between pages if the user is currently logged in
router.afterEach((to) => {
  if (store.state.user) {
    TrackingService.store({
      name: "PageViewed",
      note: `${window.location.origin}${to.fullPath.replace(/\/$/, "")}`, // Remove trailing slash if there is one
      user_id: store.state.user.id,
    });
  }
});

export default router;
