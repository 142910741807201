<template>
  <div class="tab-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 50">
      <path
        d="m33.77,12.5H3.75v25h30.02l10.86-9.04c2.16-1.8,2.16-5.11,0-6.91l-10.86-9.04Z"
        class="with-stroke additional-tag"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-width="4"
        stroke="#596573"
        fill="none"
      />
      <g>
        <path
          d="m33.77,12.5H3.75v25h30.02l10.86-9.04c2.16-1.8,2.16-5.11,0-6.91l-10.86-9.04Z"
          class="with-stroke with-fill"
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="4"
          stroke="#596573"
        />
        <circle
          class="with-stroke with-solid-fill"
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="2"
          stroke="#596573"
          fill="#596573"
          cx="35.86"
          r="1.25"
          cy="25"
        />
        <rect
          class="with-stroke line-1"
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="4"
          stroke="#596573"
          fill="none"
          height=".25"
          width="15"
          x="11.25"
          y="21.25"
        />
        <rect
          class="with-stroke line-2"
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="4"
          stroke="#596573"
          height=".25"
          width="7.5"
          fill="none"
          x="11.25"
          y="28.75"
        />
      </g>
    </svg>
  </div>
</template>
