let rootEl;
let focusableEls;
let firstFocusableEl;
let firstFocusableInput;
let lastFocusableEl;

function findFocusableChildren(el) {
  focusableEls = el.querySelectorAll(
    "button:not([disabled]), textarea:not([disabled]), *:not(.ql-hidden) > input:not([disabled]), *:not(.ql-hidden) > a[href]:not([disabled]), select:not([disabled]), p[tabindex], div[tabindex='0']"
  );
  firstFocusableEl = focusableEls[0];
  firstFocusableInput = el.querySelector(
    "textarea:not([disabled]), *:not(.ql-hidden) > input:not([disabled]), select:not([disabled])"
  );
  lastFocusableEl = focusableEls[focusableEls.length - 1];
}

function resetFocusableChildren() {
  rootEl = undefined;
  focusableEls = undefined;
  firstFocusableEl = undefined;
  firstFocusableInput = undefined;
  lastFocusableEl = undefined;
}

const observerCallback = () => {
  if (!rootEl) {
    return;
  }
  findFocusableChildren(rootEl);
};

const observer = new MutationObserver(observerCallback);

function trapFocus(e) {
  const KEYCODE_TAB = 9;
  let isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;

  if (!isTabPressed) {
    return;
  }

  if (e.shiftKey) {
    /* shift + tab */ if (document.activeElement === firstFocusableEl) {
      lastFocusableEl.focus();
      e.preventDefault();
    }
  } /* tab */ else {
    if (document.activeElement === lastFocusableEl) {
      firstFocusableEl.focus();
      e.preventDefault();
    }
  }
}


export default {
  mounted: function (el) {
    rootEl = el;
    findFocusableChildren(el);
    observer.observe(el, { subtree: true, attributeFilter: ["disabled"] });

    el.addEventListener("keydown", trapFocus);

    if (firstFocusableInput) {
      firstFocusableInput.focus();
    } else if (firstFocusableEl) {
      firstFocusableEl.focus();
    }
  },
  unmounted: function() {
    resetFocusableChildren();
    observer.disconnect();
  }
};
