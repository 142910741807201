<template>
  <svg class="doodle feedback" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <circle class="cls-5" cx="50" cy="50" r="60"/>
    <rect class="cls-2 blurb-2" x="22.5" y="21.31" width="55" height="34.38" rx="6.88" ry="6.88"/>
    <g class="blurb">
      <rect class="cls-3" x="22.5" y="35.31" width="55" height="34.38" rx="6.88" ry="6.88"/>
      <polygon class="cls-1" points="50 80.69 60.31 69.69 39.69 69.69 50 80.69"/>
      <rect class="cls-4 line-1" x="36.25" y="46.91" width="27.5" height=".25"/>
      <rect class="cls-4 line-2" x="43.12" y="58.09" width="13.75" height=".25"/>
    </g>
  </svg>
  
</template>

<style scoped>
  .cls-1, .cls-2, .cls-3 {
    stroke-width: 5.5px;
  }

  .cls-1, .cls-3 {
    fill: #be9bc9;
    stroke: #be9bc9;
  }

  .cls-1, .cls-4 {
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .cls-2 {
    fill: #333;
  }

  .cls-2, .cls-3 {
    stroke-miterlimit: 10;
  }

  .cls-2, .cls-4 {
    stroke: #333;
  }

  .cls-4 {
    fill: none;
    stroke-width: 5px;
  }

  .cls-5 {
    fill: #fff;
    stroke-width: 0px;
  }
</style>