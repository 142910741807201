<template>
  <div class="container external-page layout-not-logged-in">
    <et-header class="external" />
    <main id="main" class="container">
      <div class="narrow-container">
        <img
          class="email-sent-image"
          alt="image of an email being sent from Lettersmith"
          src="/static/images/illustrations/email-sent.png"
        />
        <h1 class="serif text-large text-highlight-primary">Check your inbox.</h1>
        <p class="text-small text-center">
          An email has been sent to the email address you provided. Follow the link in the email to
          complete registration. You can close this browser window.
        </p>
      </div>
    </main>
  </div>
</template>
