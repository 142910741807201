<template>
  <div>
    <div class="current-contributor-card mid-blue">
      <div class="contributor-info">
        <h4>James Alexander</h4>
        <p>Software Ambassador Lead</p>
      </div>
      <div class="image-container">
        <img
          src="/static/images/team-images/james.png"
          alt=""
          class="team-image"
        />
      </div>
      <a target="_blank" href="https://ai.umich.edu/staff/james-alexander/" class="button-link">View CAI Profile</a>
    </div>
    <div class="current-contributor-card orange lighter-card">
      <div class="contributor-info">
        <h4>Jessica Anders</h4>
        <p>Software Portfolio Manager</p>
      </div>
      <div class="image-container">
        <img
          src="/static/images/team-images/jess.png"
          alt=""
          class="team-image"
        />
      </div>
      <a target="_blank" href="https://ai.umich.edu/staff/jessica-anders/" class="button-link">View CAI Profile</a>
    </div>
    <div class="current-contributor-card blue-darker">
      <div class="contributor-info">
        <h4>Ben Hayward</h4>
        <p>Senior Director, Education Technology</p>
      </div>
      <div class="image-container">
        <img
          src="/static/images/team-images/ben.png"
          alt=""
          class="team-image"
        />
      </div>
      <a target="_blank" href="https://ai.umich.edu/staff/ben-hayward/" class="button-link">View CAI Profile</a>
    </div>
    <div class="current-contributor-card yellow lighter-card">
      <div class="contributor-info">
        <h4>Caitlin Hayward</h4>
        <p>Director, Research & Analytics</p>
      </div>
      <div class="image-container">
        <img
          src="/static/images/team-images/cait.png"
          alt=""
          class="team-image"
        />
      </div>
      <a target="_blank" href="https://ai.umich.edu/staff/caitlin-hayward/" class="button-link">View CAI Profile</a>
    </div>
    <div class="current-contributor-card mid-blue">
      <div class="contributor-info">
        <h4>Nasreen Mahomed</h4>
        <p>Software Developer</p>
      </div>
      <div class="image-container">
        <img
          src="/static/images/team-images/nas.png"
          alt=""
          class="team-image"
        />
      </div>
      <a target="_blank" href="https://ai.umich.edu/staff/nasreen-mahomed/" class="button-link">View CAI Profile</a>
    </div>
    <div class="current-contributor-card pink lighter-card">
      <div class="contributor-info">
        <h4>Macy Morrow</h4>
        <p>User Experience Designer Senior</p>
      </div>
      <div class="image-container">
        <img
          src="/static/images/team-images/macy.png"
          alt=""
          class="team-image"
        />
      </div>
      <a target="_blank" href="https://ai.umich.edu/staff/macy-morrow/" class="button-link">View CAI Profile</a>
    </div>
    <div class="current-contributor-card blue-darker">
      <div class="contributor-info">
        <h4>Michael Skib</h4>
        <p>Quality Assurance Lead</p>
      </div>
      <div class="image-container">
        <img
          src="/static/images/team-images/skib.png"
          alt=""
          class="team-image"
        />
      </div>
      <a target="_blank" href="https://ai.umich.edu/staff/michael-skib/" class="button-link">View CAI Profile</a>
    </div>
    <div class="current-contributor-card green lighter-card">
      <div class="contributor-info">
        <h4>Kyle Small</h4>
        <p>Software Portfolio Lead</p>
      </div>
      <div class="image-container">
        <img
          src="/static/images/team-images/kyle.png"
          alt=""
          class="team-image"
        />
      </div>
      <a target="_blank" href="https://ai.umich.edu/staff/kyle-small/" class="button-link">View CAI Profile</a>
    </div>
    <div class="current-contributor-card mid-blue">
      <div class="contributor-info">
        <h4>Michael Wheeler</h4>
        <p>Application Architect</p>
      </div>
      <div class="image-container">
        <img
          src="/static/images/team-images/michael.png"
          alt=""
          class="team-image"
        />
      </div>
      <a target="_blank" href="https://ai.umich.edu/staff/michael-wheeler/" class="button-link">View CAI Profile</a>
    </div>
  </div>
</template>