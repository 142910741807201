<template>
  <div class="draft-view__header">
    <div class="header-container">
      <h1 class="text-med new-serif bold">
        <slot name="editor-view-header-title"></slot>
      </h1>
      <slot name="editor-view-header-middle"> </slot>
    </div>
    <slot name="editor-view-header-end"> </slot>
  </div>
</template>

<script>
export default {};
</script>
