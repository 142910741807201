<template>
  <div class="tab-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 50 50">
      <g>
        <rect
          transform="translate(27.69 59.24) rotate(-135)"
          class="with-stroke with-fill"
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="4"
          stroke="#596573"
          height="31.57"
          width="10.52"
          x="20.85"
          y="8.1"
        />
        <polygon
          points="9 41 18.67 38.77 11.23 31.33 9 41"
          class="with-stroke with-solid-fill"
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="4"
          stroke="#596573"
          fill="#596573"
        />
      </g>
      <rect
        class="with-stroke line"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-width="4"
        stroke="#596573"
        height=".25"
        fill="none"
        width="0"
        y="41"
        x="9"
      />
    </svg>
  </div>
</template>
